import React from "react";

type AppIconProps = {
  width?: string;
  height?: string;
};

export const AppQualityIcon: React.FC<AppIconProps> = ({
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00001 4C5.64875 4 5.32324 4.1843 5.14252 4.4855L2.14252 9.4855C1.91703 9.86132 1.96253 10.3402 2.25477 10.6668L10.7548 20.1668C10.7648 20.178 10.7752 20.1891 10.7857 20.1999C10.944 20.3614 11.1329 20.4897 11.3414 20.5774C11.55 20.665 11.7738 20.7101 12 20.7101C12.2262 20.7101 12.4501 20.665 12.6586 20.5774C12.8671 20.4897 13.056 20.3614 13.2143 20.1999C13.2249 20.1891 13.2352 20.178 13.2453 20.1668L21.7453 10.6668C22.0375 10.3402 22.083 9.86132 21.8575 9.4855L18.8575 4.4855C18.6768 4.1843 18.3513 4 18 4H6.00001ZM4.23671 9.88248L6.5662 6H17.4338L19.7633 9.88248L12 18.5591L4.23671 9.88248ZM9.4575 9.3145C9.74165 8.84092 9.58809 8.22666 9.11451 7.94251C8.64093 7.65836 8.02667 7.81193 7.74252 8.28551L7.14252 9.28551C6.91552 9.66383 6.96329 10.1462 7.26007 10.4727L9.26007 12.6727C9.63158 13.0813 10.264 13.1115 10.6727 12.7399C11.0813 12.3684 11.1115 11.736 10.74 11.3273L9.23986 9.67723L9.4575 9.3145Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
