import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  fetchStorageById,
  saveStoragesSelect,
} from "../../api/storages/storages-acitons.api";
import { IStorageEntriesType } from "../types/storages.types";

export const storageEntitiesAdapter = createEntityAdapter<IStorageEntriesType>({
  selectId: (entity) => entity.id,
});

const storageEntitiesSlice = createSlice({
  name: "storageEntities",
  initialState: storageEntitiesAdapter.getInitialState({
    search: "",
  }),
  reducers: {
    searchStorageEntity: (state, { payload }) => {
      state.search = payload;
    },
    updateEntity: storageEntitiesAdapter.updateOne,
    setEntities: storageEntitiesAdapter.setAll,
    clearEntities: storageEntitiesAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStorageById.fulfilled, (state, action) => {
      if (action.payload.entities) {
        storageEntitiesAdapter.setAll(state, action.payload.entities);
      }
    });
    builder.addCase(saveStoragesSelect.fulfilled, (state, action) => {
      storageEntitiesAdapter.upsertMany(state, action.payload.entities as any);
    });
  },
});

export const { searchStorageEntity, updateEntity, setEntities, clearEntities } =
  storageEntitiesSlice.actions;

export const storageEntitiesReducer = storageEntitiesSlice.reducer;
