import { createSlice } from "@reduxjs/toolkit";
import { initialProcessData } from "../../../pages/create-process/create-process.page";

export type CreateProcessType = {
  createdProcess: typeof initialProcessData;
  touched: boolean;
};

const baseProcessInitialState: CreateProcessType = {
  createdProcess: {
    process_name: "",
    process_description: "",
    process_scheduler: {
      minute: null,
      hour: null,
      week: null,
      month: null,
      timezone_offset: null,
    },
    interval: "perhour" as any,
    onSchedule: "default",
    process_is_overwriting: true,
    entities: [],
    process_sql: "",
  },
  touched: false,
};

const initialState: CreateProcessType = { ...baseProcessInitialState };

const processesCreateSlice = createSlice({
  name: "processCreate",
  initialState,
  reducers: {
    setProcess: (state, { payload }) => {
      state.createdProcess = { ...state.createdProcess, ...payload };
      state.touched = true;
    },
    resetProcess: (state) => {
      state.createdProcess = { ...baseProcessInitialState.createdProcess };
      state.touched = false;
    },
    setTouched: (state, { payload }) => {
      state.touched = payload;
    },
  },
});

export const { setProcess, resetProcess, setTouched } =
  processesCreateSlice.actions;
export const processesCreateReducer = processesCreateSlice.reducer;
