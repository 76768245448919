import React, { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import MergeFormSettingsStepperLeft from "./low-code-merge-form-settings-stepper-left.component";
import MergeFormSettingsStepperRight from "./low-code-merge-form-settings-stepper-right.component";
import MergeFormSettingsStepperJoinType from "./low-code-merge-form-settings-stepper-joinType.component";
import { AppDoneIcon } from "../../../../components/icons";
import {
  IEntityType,
  Join,
  JoinDetails,
  MergeFormStepperItem,
} from "../../../../store/types/low-code.types";

import s from "./low-code-merge-modal.module.scss";

const DoneIcon = () => {
  return (
    <div className={s.icon}>
      <AppDoneIcon />
    </div>
  );
};

type PropTypes = {
  leftEntity: IEntityType;
  entities: IEntityType[];
  join: Join;
  onChange: (
    isValid: boolean,
    left?: JoinDetails,
    right?: JoinDetails,
    variant?: string
  ) => void;
};

export default memo(function ({
  leftEntity,
  entities,
  onChange,
  join,
}: PropTypes) {
  const { t } = useTranslation();
  const [mergeType, setMergeType] = useState<string | null>(null);
  const [right, setRight] = useState<MergeFormStepperItem | null>(null);
  const [left, setLeft] = useState<MergeFormStepperItem | null>(null);

  const isValid = useMemo(
    () => Boolean(mergeType && right && left),
    [mergeType, left, right]
  );

  const findAttr = (entity, attrId) =>
    entity.attributes.find(({ id }) => String(id) === String(attrId));

  const findEntity = (
    entities: IEntityType[],
    entityId: string
  ): IEntityType => {
    return entities.find(({ id }) => entityId === String(id)) as IEntityType;
  };

  useEffect(() => {
    const { variant, left, right } = join;
    const leftEntityItem = findEntity(entities, String(left?.entityId));
    const rightEntity = findEntity(entities, String(right?.entityId));

    setMergeType(variant || null);

    setLeft(
      left
        ? {
            entity: leftEntityItem,
            attribute: findAttr(leftEntityItem, String(left?.attributeId)),
          }
        : null
    );

    setRight(
      right
        ? {
            entity: rightEntity,
            attribute: findAttr(rightEntity, String(right?.attributeId)),
          }
        : null
    );
  }, [leftEntity]);

  useEffect(() => {
    if (!isValid) {
      onChange(isValid);
      return;
    }

    onChange(
      isValid,
      {
        entityId: String(left?.entity.id),
        attributeId: String(left?.attribute.id),
      },
      {
        entityId: String(right?.entity.id),
        attributeId: String(right?.attribute.id),
      },
      String(mergeType)
    );
  }, [mergeType, right, left]);

  const leftPart = () => (
    <>
      {join.index > 1 ? (
        <MergeFormSettingsStepperRight
          entities={entities}
          countAvailableEntities={5}
          data={left}
          onChange={(entity, attribute) => {
            if (entity && attribute) {
              setLeft({ entity, attribute });
            }
          }}
        />
      ) : (
        <MergeFormSettingsStepperLeft
          leftAttributeId={left ? String(left?.attribute?.id) : null}
          leftEntity={leftEntity}
          onChange={(value: string) => {
            setLeft({
              entity: leftEntity,
              attribute: findAttr(leftEntity, value),
            });
          }}
        />
      )}
    </>
  );

  return (
    <div className={s.stepper}>
      <div className={s.step}>
        <div className={s.heading}>
          <div className={s.text}>
            <span className={s.subheading}>
              {t("page.process.form.step.lowcode.merge.step.one")}
            </span>
            <span className={s.description}>
              {t("page.process.form.step.lowcode.merge.step.one.help")}
            </span>
          </div>
          {left?.attribute.id && <DoneIcon />}
        </div>
        {leftPart()}
      </div>

      <div className={s.step}>
        <div className={s.heading}>
          <div className={s.text}>
            <span className={s.subheading}>
              {t("page.process.form.step.lowcode.merge.step.two")}
            </span>
            <span className={s.description}>
              {t("page.process.form.step.lowcode.merge.step.two.help")}
            </span>
          </div>
          {mergeType && <DoneIcon />}
        </div>
        <MergeFormSettingsStepperJoinType
          mergeType={mergeType ?? ""}
          onChange={(mergeType) => {
            setMergeType(mergeType);
          }}
        />
      </div>

      <div className={s.step}>
        <div className={s.heading}>
          <div className={s.text}>
            <span className={s.subheading}>
              {t("page.process.form.step.lowcode.merge.step.three")}
            </span>
            <span className={s.description}>
              {t("page.process.form.step.lowcode.merge.step.three.help")}
            </span>
          </div>
          {right && <DoneIcon />}
        </div>
        <MergeFormSettingsStepperRight
          entities={entities}
          countAvailableEntities={5}
          attributeType={left?.attribute?.attribute_type}
          data={right}
          onChange={(entity, attribute) => {
            if (entity && attribute) {
              setRight({ entity, attribute });
            } else {
              setRight(null);
            }
          }}
        />
      </div>
    </div>
  );
});
