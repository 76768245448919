import { Checkbox, Select, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import type { FilterFunc, DefaultOptionType } from "rc-select/lib/Select";

import s from "../create-process.module.scss";
import { getStorageIdByKey } from "./helper";

import { AppInput } from "../../../components/app-input/app-input.component";
import { AppSchemeIcon } from "../../../components/icons/app-scheme.icon";
import { AppCheckbox } from "../../../components/app-checkbox/app-checkbox.component";
import { AppSearchIcon } from "../../../components/icons/app-search.icon";
import { IntroContext } from "../../../providers/intro-provider/intro.provider";
import { AppButton } from "../../../components/app-button/app-button.component";
import { AppInfoIcon } from "../../../components/icons/app-info.icon";
import { Trans, useTranslation } from "react-i18next";

const { Option } = Select;
export const STORAGE_ID_PREFIX = "storageid";

export const Step2 = ({ storages, processData, onUpdate, onBack }: any) => {
  const [storageSearch, setStorageSearch] = useState(storages);
  const [searchValue, setSearchValue] = useState("");
  const { autoComplete } = useContext(IntroContext);

  const { t } = useTranslation();

  const formInstance = useForm({
    mode: "onChange",
    defaultValues: {
      storageIds: processData.rawStorageIds || [],
      entities: processData.rawEntities || {},
      selectedSearch: [],
    },
  });
  const {
    control,
    unregister,
    watch,
    setValue,
    getFieldState,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = formInstance;

  const watchEntityIds = watch("storageIds");

  const submitForm = (data: any) => {
    const prepareEntities = Object.entries(data.entities).reduce<
      { id: number }[]
    >((acc, curr: any) => {
      if (Array.isArray(curr[1])) {
        return [...acc, ...curr[1].map((id) => ({ id }))];
      }
      return acc;
    }, []);
    onUpdate({
      entities: prepareEntities,
      rawStorageIds: data.storageIds,
      rawEntities: data.entities,
    });
  };

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      setValue("storageIds", [...watchEntityIds, checkboxValue]);
    } else {
      setValue(
        "storageIds",
        watchEntityIds.filter((value) => value !== checkboxValue)
      );
    }
  };

  const storageEntities = storages
    ? watchEntityIds.map((id: number) => {
        return storages.find(
          (storage: { id: any }) => Number(storage.id) === Number(id)
        );
      })
    : [];

  const handleFilterSearchStoragses: FilterFunc<DefaultOptionType> = (
    input,
    option
  ) => {
    return String(option?.children ?? "")
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  useEffect(() => {
    // Выполняю сравнение текущих источников и значений сущностей в форме
    // Если есть несовпадения, то удаляю данные по тем источникам которых уже нет в форме
    const entitiesValue = getValues("entities") || {};
    Object.keys(entitiesValue).forEach((key) => {
      const storageId = getStorageIdByKey(key);
      if (!watchEntityIds.includes(storageId)) {
        unregister(`entities.${name}`);
      }
    });
  }, [watchEntityIds]);

  const onChangeSearch = (value: string) => {
    setSearchValue(value);
    const result = storages.filter((storage) => {
      return storage.storage_name.toLowerCase().includes(value.toLowerCase());
    });
    if (value) {
      setStorageSearch(result);
    } else {
      setStorageSearch(storages);
    }
  };

  if (!storages) return null;

  return (
    <form
      className={s.formWrapper}
      onSubmit={handleSubmit((data) => submitForm(data))}
    >
      <div className={s.formTitleContainer}>
        <h3 className={s.formTitle}>
          {t("page.process.form.step.two.title")}
          <Tooltip
            placement={"topLeft"}
            title={
              <Trans
                t={t}
                i18nKey={"page.process.form.step.two.title.tooltip"}
                components={[<span key={0} className={s.textBlue}></span>]}
              ></Trans>
            }
            color={"var(--white)"}
          >
            <div className={s.helpIconContainer}>
              <AppInfoIcon sharedStyles={s.helpIcon} />
            </div>
          </Tooltip>
        </h3>
      </div>
      <div className={s.stepTwoContainer}>
        <div className={s.searchWrapper}>
          <AppInput
            icon={<AppSearchIcon />}
            sharedStyles={s.searchInput}
            onChange={(value) => onChangeSearch(value.currentTarget.value)}
            autoComplete={autoComplete}
            name={"search"}
            control={control}
            placeholder={t("page.source.search") as string}
          />
          <div className={s.currentSearch}>
            <div className={s.scrollContainer}>
              {storages && (
                <>
                  {!searchValue.length ? (
                    <>
                      {" "}
                      <AppCheckbox
                        name={"storageIds"}
                        control={control}
                        direction={"vertical"}
                      >
                        {storages.map((conn: any) => (
                          <Checkbox value={conn.id} key={conn.storage_name}>
                            <div className={s.stepTwoCheckBox}>
                              {conn.storage_name}
                              <span>{conn.storage_name_slug}</span>
                            </div>
                          </Checkbox>
                        ))}
                      </AppCheckbox>
                    </>
                  ) : (
                    <>
                      <AppCheckbox
                        name={"selectedSearch"}
                        control={control}
                        direction={"vertical"}
                      >
                        {storageSearch.map((conn: any) => (
                          <Checkbox
                            value={conn.id}
                            key={conn.storage_name}
                            onChange={handleCheckboxChange}
                          >
                            <div className={s.stepTwoCheckBox}>
                              {conn.storage_name}
                              <span>{conn.storage_name_slug}</span>
                            </div>
                          </Checkbox>
                        ))}
                      </AppCheckbox>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={s.stepTwoEntities}>
          <p>{t("page.process.form.step.two.choice")}</p>

          {storageEntities.length > 0 ? (
            <div>
              {storageEntities.map(
                ({ id, storage_name, entities }: any, index: number) => (
                  <div className={s.entityWrapper} key={`${id}_${index}`}>
                    <div className={s.entityTitle}>
                      <AppSchemeIcon />
                      {t("navigation.source.one")}: {storage_name}
                    </div>
                    <AppInput
                      control={control}
                      showArrow={true}
                      showSearch={true}
                      filterOption={handleFilterSearchStoragses}
                      rules={{
                        required: t(
                          "page.process.form.step.validation.required"
                        ) as string,
                      }}
                      errors={errors}
                      name={`entities.${STORAGE_ID_PREFIX}_${id}`}
                      placeholder={
                        t(
                          "page.process.form.step.two.choice.placeholder"
                        ) as string
                      }
                      mode="multiple"
                    >
                      <></>
                      <>
                        {entities.map((entity: any) => (
                          <Option value={entity.id} key={entity.id}>
                            {entity.entity_name}
                          </Option>
                        ))}
                      </>
                    </AppInput>
                  </div>
                )
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className={s.formFooterButtons}>
        <AppButton isOutline onClick={onBack}>
          {t("page.process.form.step.action.back")}
        </AppButton>
        <AppButton disabled={!isValid} htmlType={"submit"}>
          {t("steps.action.next")}
        </AppButton>
      </div>
    </form>
  );
};
