import React, { memo, useContext } from "react";
import {
  Handle,
  NodeProps,
  Position,
  useNodeId,
  useReactFlow,
  getConnectedEdges,
} from "reactflow";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { AppSmallCrossIcon, AppMergeIcon } from "../icons";
import { AppButton, EButtonSize } from "../app-button/app-button.component";
import { TransformContext } from "../../pages/create-process/steps/transform.provider";
import { showMessage } from "../../store/snackbar/snackbar.slice";
import { ESnackbarState } from "../../store/types/snackbar.types";
import { useAppDispatch } from "../../store/hooks";
import s from "./node.module.scss";

export type TMergeTransformNode = {
  index: number;
};

export const MergeTransformNode: React.FC<NodeProps<TMergeTransformNode>> =
  memo(({ data, selected }) => {
    const rf = useReactFlow();
    const nodeId = useNodeId();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { onChangeSettings, onEmitDeleteNode } = useContext(TransformContext);

    const handleDelete = (event: React.MouseEvent) => {
      event.stopPropagation();
      onEmitDeleteNode(String(nodeId));
    };

    const hasIncomers = (): boolean => {
      const node = rf.getNode(String(nodeId));
      if (!node) return false;

      const incomers = getConnectedEdges([node], rf.getEdges()).filter(
        ({ target }) => target === nodeId
      );
      return Boolean(incomers.length);
    };

    const handleClick = () => {
      if (!hasIncomers()) {
        dispatch(
          showMessage({
            message: t("page.process.form.step.lowcode.edges.error"),
            type: ESnackbarState.ERROR,
          })
        );
        return;
      }

      onChangeSettings(String(nodeId));
    };

    return (
      <>
        <div
          data-testid={`transform-${nodeId}`}
          className={cn(s.customNode, s._merge, {
            [s.customNodeSelected]: selected,
          })}
        >
          <div
            className={s.customNodeClose}
            onClick={handleDelete}
            role="img"
            aria-label="remove merge"
          >
            <AppSmallCrossIcon />
          </div>
          <div className={cn(s.customNodeTitle, s._mergeTitle)}>
            <AppMergeIcon width="14" height="14" className={s.transformIcon} />
            <span>
              {t("page.process.form.step.lowcode.transformation.node.merge")}{" "}
            </span>
            <Tag>{data.index}</Tag>
          </div>
          <div className={s.customNodeBody}>
            <AppButton
              buttonSize={EButtonSize.FILL}
              isSmall={true}
              onClick={handleClick}
            >
              {t("page.process.form.step.lowcode.merge.tune")}
            </AppButton>
          </div>
        </div>
        <Handle
          type="target"
          position={Position.Left}
          id={`target-merge-${data.index}`}
          style={{ background: "#b7b7b7", width: "8px", height: "8px" }}
        />
        <Handle
          type="source"
          position={Position.Right}
          isConnectable={hasIncomers()}
          id={`source-merge-${data.index}`}
          style={{ background: "#ddd", width: "8px", height: "8px" }}
        />
      </>
    );
  });
