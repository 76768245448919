import s from "./origin-page.module.scss";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import { Modal, Tooltip } from "antd";

import { AppContent } from "../../components/app-main.layout";
import { AppCreateCurrent } from "../../components/forms/app-create-current-form/app-create-current.form";
import { AppArrowIcon } from "../../components/icons/app-arrow.icon";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchRules,
  fetchStorageById,
  saveStoragesSelect,
} from "../../api/storages/storages-acitons.api";
import { truncateText } from "../../helpers/truncate-text.helper";

import { AppOriginTabs } from "../../components/origin/app-origin-tabs/app-origin-tabs.component";
import { AppOriginTable } from "../../components/origin/app-origin-table/app-origin-table.component";
import { AppButton } from "../../components/app-button/app-button.component";
import { AppSaveIcon } from "../../components/icons/app-save.icon";
import { AppConditionSwitcher } from "../../components/app-condition-switcher/app-condition-switcher.component";
import { AppOriginTree } from "../../components/origin/app-origin-tree/app-origin-tree.component";
import { storageEntitiesSelectors } from "../../store/storages/storage-entities.selector";
import { OriginHeaderActions } from "../../components/origin/origin-header-actions/origin-header-actions.component";
import {
  IEntriesAttributeTypes,
  IStorageEntriesType,
  IStorageType,
} from "../../store/types/storages.types";
import { storagesSelectors } from "../../store/storages/storages.selector";
import { storageEntityAttributesSelectors } from "../../store/storages/storage-entity-attributes.selector";
import isEqual from "lodash.isequal";
import { AppLogoLoader } from "../../components/ui/app-animated-logo/app-animated-logo.component";
import { clearEntities } from "../../store/storages/storage-entities.slice";
import { useTranslation } from "react-i18next";
import {
  setCurrentStorage,
  resetCurrentStorage,
} from "../../store/storages/storage.slice";
import { selectStorage } from "../../store/storages/storage.selector";

export const OriginPage = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [query] = useSearchParams();
  const storageId = Number(params.id);
  const entityId = Number(query.get("tab"));
  const { t } = useTranslation();

  const { currentStorage } = useAppSelector(selectStorage);
  const { storage_import_status } = currentStorage;
  const storageData = useAppSelector((state) =>
    storagesSelectors.selectById(state, storageId)
  ) as IStorageType;
  const storageEntitiesTotal = useAppSelector(
    storageEntitiesSelectors.selectTotal
  );
  const entity = useAppSelector((state) =>
    storageEntitiesSelectors.selectById(state, entityId)
  );
  const storageEntityAttributes = useAppSelector((state) =>
    storageEntityAttributesSelectors.selectEntities(state)
  );

  const [disableStorageActions, setDisableStorageActions] = useState(false);
  const [showSettingStorageModal, setShowSettingStorageModal] = useState(false);
  const [view, changeView] = useState(false);
  const [modifiedAttribute, setModifiedAttribute] = useState<{
    [key: string]: IEntriesAttributeTypes;
  }>({});
  const modifiedAttributeIds = Object.keys(modifiedAttribute).map((id) =>
    Number(id)
  );

  const disableActions = () => {
    if (
      storage_import_status === "starting" ||
      storage_import_status === "stopping_inprogress" ||
      storage_import_status === "running" ||
      storage_import_status === "stopping_started"
    ) {
      setDisableStorageActions(true);
    } else {
      setDisableStorageActions(false);
    }
  };

  useEffect(() => {
    disableActions();
  }, [currentStorage]);

  /** Проверка на наличие хотя-бы одного выбранного атрибута */

  const canEdit = Object.values(storageEntityAttributes).some(
    (attr) => attr?.attribute_is_selected
  );

  const entityType = storageData?.storage_datasource_code;
  /** Мерж данных из стора с модифицированными данными из таблицы, пока данные не сохранены */
  const attributes = entity
    ? entity.attributes.map((attrId: any) =>
        modifiedAttribute[attrId]
          ? modifiedAttribute[attrId]
          : storageEntityAttributes[attrId]
      )
    : [];

  /** Проверка, выбран ли хотя бы 1 аттрибут */
  const isSomeSelectedEntity = entity
    ? attributes.some((attr: any) => attr.attribute_is_selected)
    : false;

  const handleShowSettingStorageModal = () => setShowSettingStorageModal(true);
  const handleHideSettingStorageModal = () => setShowSettingStorageModal(false);

  const saveAttributeSettings = () => {
    // Если выбран хотябы 1 аттрибут то помечаем сущность
    const _entity = {
      ...entity,
      entity_is_selected: isSomeSelectedEntity,
    };

    const _modifiedEntityAttribute = entity?.attributes.reduce(
      (acc, attributeId: any) => {
        if (modifiedAttribute[attributeId]) {
          return { ...acc, [attributeId]: modifiedAttribute[attributeId] };
        }
        return { ...acc, [attributeId]: storageEntityAttributes[attributeId] };
      },
      {}
    );

    dispatch(
      saveStoragesSelect({
        storageId,
        entity: _entity as IStorageEntriesType,
        attributes: {
          ...storageEntityAttributes,
          ..._modifiedEntityAttribute,
        } as any,
      })
    )
      .unwrap()
      .then(() => {
        // После того как сохранили источник, удаляем модифицированные аттрибуты из конкретной сущности
        const _modifiedAttribute = { ...modifiedAttribute };
        entity?.attributes.forEach((id: any) => {
          delete _modifiedAttribute[id];
        });

        setModifiedAttribute(_modifiedAttribute);
      });
  };

  const handleEntitySelected = (
    entityIsSelected: boolean,
    attributes: IEntriesAttributeTypes[]
  ) => {
    if (!entity) return;

    // Сохраняем в локальный стейт все измененные аттрибуты как модифицированные
    const _modified = attributes.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: {
          ...storageEntityAttributes[curr.id],
          attribute_is_selected: entityIsSelected,
        },
      };
    }, {});
    setModifiedAttribute(_modified);
  };

  const handleAttributeSelected = (
    id: number,
    changes: Partial<IEntriesAttributeTypes>
  ) => {
    const _attribute = { ...storageEntityAttributes[id], ...changes };

    if (
      modifiedAttribute[id] &&
      isEqual(_attribute, storageEntityAttributes[id])
    ) {
      const _modified = { ...modifiedAttribute };
      delete _modified[id];
      setModifiedAttribute(_modified);
    } else {
      setModifiedAttribute({
        ...modifiedAttribute,
        [id]: _attribute,
      });
    }
  };

  /** Lifecycle */
  useEffect(() => {
    dispatch(fetchStorageById(storageId)).then((data: any) => {
      const { id, storage_import_type, storage_import_status } =
        data.payload.storage[storageId];

      const currentStorage = {
        id,
        storage_import_type,
        storage_import_status,
      };
      dispatch(setCurrentStorage(currentStorage));
    });
    dispatch(fetchRules());

    return () => {
      dispatch(clearEntities());
    };
  }, []);

  /** Render */
  const renderTitle = useCallback(() => {
    const { text, isTruncated } = truncateText(storageData.storage_name, 24);
    if (isTruncated) {
      return (
        <Tooltip title={storageData.storage_name} color={"var(--white)"}>
          <span>{text}</span>
        </Tooltip>
      );
    } else {
      return <span>{text}</span>;
    }
  }, [storageData?.storage_name]);

  const renderSaveButton = () => {
    if (attributes.length) {
      return (
        <div className={s.saveContainer}>
          <AppButton
            isFilled={!disableStorageActions}
            id={"save-button"}
            onClick={saveAttributeSettings}
            sharedStyles={s.rightBarButton}
            disabled={disableStorageActions}
          >
            {t("source.detail.card.save_changes")}
            <AppSaveIcon />
          </AppButton>
        </div>
      );
    }
  };

  if (!storageData)
    return (
      <AppContent isFull>
        <AppLogoLoader loading={true} />
      </AppContent>
    );

  return (
    <>
      <AppContent isFull>
        <div className={s.originSettings}>
          <div className={s.actionBlock} id={"first-current"}>
            <NavLink to={"/home"} className={s.originBlock}>
              <AppArrowIcon side={"left"} />
              <span>{renderTitle()}</span>{" "}
            </NavLink>
            {Boolean(storageEntitiesTotal) && (
              <>
                <div className={s.divider}></div>
                <AppConditionSwitcher
                  uncheckedValue={t("source.detail.card.view.table")}
                  checkedValue={t("source.detail.card.view.diagram")}
                  onChange={changeView}
                />
              </>
            )}
          </div>
          <OriginHeaderActions
            disableStorageActions={disableStorageActions}
            storageId={storageId}
            storageSourceType={entityType}
            isSelectedEntity={canEdit}
            onShowSettingStorage={handleShowSettingStorageModal}
          />
        </div>
        {storageEntitiesTotal ? (
          <>
            {view ? (
              <AppOriginTree />
            ) : (
              <div className={s.originContent}>
                <AppOriginTabs modifiedAttributeIds={modifiedAttributeIds} />
                <div className={s.originTable}>
                  {entity ? (
                    <AppOriginTable
                      disableStorageActions={disableStorageActions}
                      entity={entity}
                      entityType={entityType}
                      attributes={attributes as IEntriesAttributeTypes[]}
                      storageId={storageId}
                      isSelectedEntity={isSomeSelectedEntity}
                      onEntitySelected={handleEntitySelected}
                      onAttributeSelected={handleAttributeSelected}
                    />
                  ) : null}
                  {renderSaveButton()}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={s.originEmpty}>
            {t("source.detail.card.flow.no_source")}
          </div>
        )}
      </AppContent>

      <Modal
        open={showSettingStorageModal}
        footer={false}
        onCancel={handleHideSettingStorageModal}
        title={t("modal.source.create.title.edit")}
      >
        <AppCreateCurrent
          origin={storageData}
          onCancel={handleHideSettingStorageModal}
        />
      </Modal>
    </>
  );
};
