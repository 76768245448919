import type { Node } from "reactflow";
import {
  MergeDataType,
  MergeIncomerType,
} from "../../../store/types/low-code.types";
import { Merge } from "./index";

export class Schema {
  process: { process_name: string; process_description: string };
  tree: Merge;

  constructor(processData: {
    process_name: string;
    process_description: string;
  }) {
    this.process = processData;
    this.tree = new Merge();
  }

  add(input: MergeDataType, parentKey?: string | null): void {
    const parent = parentKey ? this.tree.find(parentKey) : this.tree;
    parent?.add(new Merge(input));
  }

  find(key: string): Merge | undefined {
    return this.tree.find(key);
  }

  remove(key: string | undefined): Node[] | undefined {
    if (key) {
      return this.tree.remove(key);
    }
  }

  getResult(index: number): MergeIncomerType | undefined {
    return this.tree.find(`merge_${index}`)?.getResult();
  }

  toObject() {
    return this.tree.toObject();
  }

  getEntities() {
    return this.tree.getEntities();
  }

  getEdges() {
    return this.tree.getEdges();
  }

  private parseIncomers(attrs) {
    const attrsByEntity = attrs.reduce((acc, attr) => {
      acc[attr.attributeSchema] = acc[attr.attributeSchema] || {
        id: attr.entityId,
        type: "process",
        entity_id: attr.entityId,
        entity_name: attr.attributeSchema,
        attributes: [],
      };
      acc[attr.attributeSchema].attributes.push({
        id: attr.id,
        entity_id: attr.entityId,
        attribute_schema: attr.attributeSchema,
        attribute_name: attr.attributeName,
        attribute_name_slug: attr.attributeNameSlug,
        attribute_type: attr.attributeType,
      });
      return acc;
    }, Object.create(null));

    return Object.values(attrsByEntity).map((entity) => ({
      entity,
    })) as MergeIncomerType[];
  }

  init({ tree, rf }: any) {
    tree.map((leaf) => {
      const node = rf.nodes.find(
        ({ id }) => id === `${leaf.type}_${leaf.index}`
      );

      const item = {
        name: leaf.name,
        incomers: this.parseIncomers(leaf.results.attributes),
        node,
        joins: leaf.settings.map((item) => Object.assign({}, item)),
      };

      const parentKey = leaf.parent
        ? `${leaf.parent.type}_${leaf.parent.index}`
        : null;

      this.add(item, parentKey);
    });
  }
}
