import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Steps, Tag } from "antd";

import { fetchStorageForProcess } from "../../api/storages/storages-acitons.api";
import {
  archiveProcessAction,
  fetchProcessById,
} from "../../api/processes/processes-actions.api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setTouched } from "../../store/processes/process-create/process-create.slice";
import { setCurrentProcess } from "../../store/processes/process-detail/process-detail.slice";
import { selectProcessDetail } from "../../store/processes/process-detail/process-detail.selector";
import {
  EProcessStatus,
  IDomainProcess,
} from "../../store/types/processes.types";

import { AppContent } from "../../components/app-main.layout";
import { StepDetail1 } from "./steps/step-detail1.component";
import { Step3 } from "./steps/step-detail3.component";
import { StepViewLowCodePlace } from "./steps/step-view-low-code-place.component";
import { AppConfirmModal } from "../../components/ui/app-confirm-modal/app-confirm-modal.component";
import { AppArrowIcon } from "../../components/icons/app-arrow.icon";
import { OnlineAccess } from "../../components/app-online/app-online.component";
import { CardMenuItem } from "../../components/ui/app-card/app-card.component";
import { AppEditIcon } from "../../components/icons/app-edit.icon";

import s from "./process-detail.module.scss";

type PropTypes = { isLowcode?: boolean };

const PROCESS_ROUTE = "/processes";

export const ProcessDetailPage: React.FC<PropTypes> = ({
  isLowcode = false,
}) => {
  const [storages, setStorages] = useState<any>(null);
  const [current, setCurrent] = useState(0);
  const [archiveProcessModal, showArchiveProcessModal] =
    useState<IDomainProcess | null>(null);

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentProcess } = useAppSelector(selectProcessDetail);
  const { process_schema, process_status_flow } = currentProcess;

  useEffect(() => {
    dispatch(fetchProcessById(Number(id))).then((data) => {
      dispatch(setCurrentProcess(data.payload.data));
    });
  }, []);

  useEffect(() => {
    return () => {
      setTouch(false);
    };
  }, []);

  useEffect(() => {
    //FIXME: можно будет убрать если нормализовать стейт для источников и сущностей
    dispatch(fetchStorageForProcess())
      .unwrap()
      .then((data) => {
        const filtered = data.data.filter(
          (storage: { entities: string | any[] }) => storage.entities.length > 0
        );
        setStorages(filtered);
      });
  }, []);

  const handleHideArchiveProcessModal = () => {
    showArchiveProcessModal(null);
  };

  const handleSubmitConfirmArchiveProcess = () => {
    if (archiveProcessModal) {
      dispatch(
        archiveProcessAction({
          id: archiveProcessModal.id,
          process_is_deleted:
            archiveProcessModal.process_status_flow !== EProcessStatus.ARCHIVED,
        })
      );
    }

    showArchiveProcessModal(null);
  };

  const next = useCallback(
    (count) => {
      setCurrent(current + count);
    },
    [current]
  );

  const back = useCallback(
    (count) => {
      setCurrent(current - count);
    },
    [current]
  );

  const setTouch = (condition: boolean) => {
    dispatch(setTouched(condition));
  };

  const editProcess = (): void => {
    navigate(`/processes/${id}/edit`);
  };

  const steps = process_schema
    ? [
        {
          title: t("page.process.form.step.basic_details"),
          className: s.processBody,
          content: <StepDetail1 onNext={() => next(1)} isLowcode={isLowcode} />,
        },
        {
          title: t("page.process.form.step.enter_request"),
          className: s.processLowCodeBody,
          content: (
            <StepViewLowCodePlace
              storages={storages as any}
              onBack={() => back(1)}
            />
          ),
        },
      ]
    : [
        {
          title: t("page.process.form.step.basic_details"),
          className: s.processBody,
          content: <StepDetail1 onNext={() => next(2)} />,
        },
        {
          title: t("page.process.form.step.source_chose"),
          className: s.processBody,
          content: <></>,
        },
        {
          title: t("page.process.form.step.enter_request"),
          className: s.processBody,
          content: <Step3 onBack={() => back(2)} />,
        },
      ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <AppContent isFull>
        <OnlineAccess>
          <div className={s.processHead}>
            <div className={s.leftBlock} id={"first-current"}>
              <NavLink to={PROCESS_ROUTE} className={s.processBlock}>
                <AppArrowIcon side={"left"} />
                <span>{t("page.process-detail.header")}</span>
              </NavLink>
              {process_status_flow && (
                <Tag color="#0057ff33">{process_status_flow}</Tag>
              )}
            </div>
            <div className={s.rigntBlock}>
              <CardMenuItem
                className={s.editBtn}
                text={t("page.process.card.actions.edit")}
                isDisabled={process_status_flow !== EProcessStatus.DRAFT}
                icon={<AppEditIcon />}
                onClick={editProcess}
              />
              <Steps
                className={s.stepsContainer}
                current={current}
                items={items}
              />
            </div>
          </div>
          <div className={steps[current].className || ""}>
            {steps[current].content}
          </div>
        </OnlineAccess>
      </AppContent>
      <AppConfirmModal
        open={Boolean(archiveProcessModal)}
        title={
          archiveProcessModal?.process_status_flow === EProcessStatus.ARCHIVED
            ? "page.process.card.archive.form.title"
            : "page.process.card.archive.form.confirm"
        }
        isDanger={
          archiveProcessModal?.process_status_flow !== EProcessStatus.ARCHIVED
        }
        btnTextConfirm={
          archiveProcessModal?.process_status_flow === EProcessStatus.ARCHIVED
            ? "page.process.card.actions.unarchive"
            : "page.process.card.actions.archive"
        }
        onConfirm={handleSubmitConfirmArchiveProcess}
        onCancel={handleHideArchiveProcessModal}
      >
        {archiveProcessModal ? (
          <>
            {archiveProcessModal.process_status_flow ===
            EProcessStatus.ARCHIVED ? (
              <p className="mb-16">
                <Trans
                  i18nKey={
                    "page.process.card.archive.form.description.one.unarchive"
                  }
                  t={t}
                  values={{
                    process_name: archiveProcessModal.process_name,
                  }}
                  components={[
                    <span
                      key={0}
                      style={{ color: "var(--accent-first)" }}
                    ></span>,
                  ]}
                ></Trans>
              </p>
            ) : (
              <p className="mb-16">
                <Trans
                  i18nKey={
                    "page.process.card.archive.form.description.one.archive"
                  }
                  t={t}
                  values={{
                    process_name: archiveProcessModal.process_name,
                  }}
                  components={[
                    <span
                      key={0}
                      style={{ color: "var(--accent-first)" }}
                    ></span>,
                  ]}
                ></Trans>
              </p>
            )}

            {archiveProcessModal.process_status_flow ===
            EProcessStatus.ARCHIVED ? null : (
              <p className="mb-16">
                {t("page.process.card.archive.form.description.three")}
              </p>
            )}
            <p>{t("page.process.card.archive.form.description.two")}</p>
          </>
        ) : null}
      </AppConfirmModal>
    </>
  );
};
