import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
  fetchStorageById,
  saveStoragesSelect,
} from "../../api/storages/storages-acitons.api";
import { IEntriesAttributeTypes } from "../types/storages.types";

export const storageEntityAttributesAdapter =
  createEntityAdapter<IEntriesAttributeTypes>({
    selectId: (attr) => attr.id,
  });

export const attributeOriginsAdapter =
  createEntityAdapter<IEntriesAttributeTypes>({
    selectId: (attr) => attr.id,
  });

const storageEntityAttributesSlice = createSlice({
  name: "storageEntityAttributes",
  initialState: storageEntityAttributesAdapter.getInitialState(),
  reducers: {
    updateAttribute: storageEntityAttributesAdapter.updateOne,
    updateManyAttribute: storageEntityAttributesAdapter.updateMany,
    setAttributes: storageEntityAttributesAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStorageById.fulfilled, (state, action) => {
      if (action.payload.attributes) {
        storageEntityAttributesAdapter.setAll(state, action.payload.attributes);
      }
    });
    builder.addCase(saveStoragesSelect.fulfilled, (state, action) => {
      storageEntityAttributesAdapter.upsertMany(
        state,
        action.payload.attributes
      );
    });
  },
});

export const { updateAttribute, updateManyAttribute, setAttributes } =
  storageEntityAttributesSlice.actions;

export const storageEntityAttributesReducer =
  storageEntityAttributesSlice.reducer;
