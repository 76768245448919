import { Modal } from "antd";
import React from "react";
import { IStorageEntriesType } from "../../../../store/types/storages.types";
import { AppFileForm } from "../../../forms/app-file-form/app-file.form";

import s from "../app-origin-table.module.scss";
import { useTranslation } from "react-i18next";

type PropTypes = {
  entity: IStorageEntriesType | undefined;
  fileFormat: string;
  storageId: number;
  open: boolean;
  onCancel: () => void;
};

export const UploadFileModal: React.FC<PropTypes> = ({
  entity,
  fileFormat,
  storageId,
  open,
  onCancel,
}) => {
  const { t } = useTranslation();

  if (!entity) return null;

  return (
    <Modal
      destroyOnClose={true}
      open={open}
      footer={false}
      onCancel={onCancel}
      title={
        <div className={s.tableModalHeader}>
          <span>
            {t("source.detail.card.actions.actions_button.upload_file.title", {
              fileFormat,
            })}
          </span>
          <div className={s.modalEntity}>
            <span>
              {t(
                "source.detail.card.actions.actions_button.upload_file.title",
                { fileFormat }
              )}
              :{" "}
            </span>
            <span>{entity.entity_name}</span>
          </div>
        </div>
      }
    >
      <AppFileForm
        entityId={entity.id}
        storageId={storageId}
        onClose={onCancel}
        fileFormat={fileFormat}
      />
    </Modal>
  );
};
