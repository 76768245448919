import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import cn from "classnames";
import { useForm } from "react-hook-form";

import { AppInput } from "../../../components/app-input/app-input.component";
import { AppButton } from "../../../components/app-button/app-button.component";
import { Alert } from "../../../components/ui/app-alert/app-alert.component";
import { FinishModal } from "../../create-process/steps/finish-modal/finish-modal.component";
import { EProcessStatus } from "../../../store/types/processes.types";
import { DataTree } from "../../create-process/steps/data-tree/data-tree.compnent";
import { getStorageIdByKey } from "../../create-process/steps/helper";

import s from "../../create-process/create-process.module.scss";

export const StepEdit3 = ({
  onBack,
  onExit,
  onSubmit,
  sql,
  processData,
  storages,
}: any) => {
  const { t } = useTranslation();

  const formInstance = useForm({
    mode: "onChange",
    defaultValues: { process_sql: sql },
  });

  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = formInstance;

  const [showFinishModal, setShowFinishModal] = useState(false);

  const rawEntities = processData?.rawEntities || {};
  const storageList = Object.keys(rawEntities).map((key) => {
    const storageId = getStorageIdByKey(key);
    const storage = storages.find(
      ({ id }: { id: number }) => Number(id) === Number(storageId)
    );
    const storageCopy = JSON.parse(JSON.stringify(storage)); // Error: object is not extensible
    storageCopy.selectedEntities = storage.entities.filter((entity: any) =>
      processData.rawEntities[key].includes(entity.id)
    );

    return storageCopy;
  });

  const handleShowFinishModal = () => {
    setShowFinishModal(true);
  };

  const handleHideFinishModal = () => {
    setShowFinishModal(false);
  };

  const handleSaveActivate = () => {
    const process_sql = getValues("process_sql");
    onSubmit({
      process_sql,
      process_status: EProcessStatus.ACTIVE,
    });
  };

  const handleSaveDraft = () => {
    const process_sql = getValues("process_sql");
    onSubmit({
      process_sql,
      process_status: EProcessStatus.DRAFT,
    });
  };

  const handleWriteSqlData = (data: string) => {
    const previousValue = getValues("process_sql");
    setValue("process_sql", `${previousValue + " " + data}`);
  };

  return (
    <>
      <form className={cn(s.formWrapper, s.formWrapperLast)}>
        <div className={s.formTitleContainer}>
          <h3 className={s.formTitle}>{t("page.process-edit.header")}</h3>
        </div>
        <div className={s.stepTwoContainer}>
          <div className={s.searchWrapper}>
            <DataTree
              storageList={storageList}
              handleWriteSqlData={handleWriteSqlData}
              control={control}
            />
          </div>

          <div className={s.stepTwoEntities}>
            <AppInput
              sharedStyles={s.sqlTextArea}
              rules={{
                required: t(
                  "page.process.form.step.validation.required"
                ) as string,
              }}
              errors={errors}
              control={control}
              name={"process_sql"}
              isTextArea={true}
            />
            <p className={s.helpSql}>
              <Alert title={"page.process.form.step.three.hint"}>
                <div>
                  <Trans
                    t={t}
                    i18nKey={"page.process.form.step.three.description"}
                    components={[
                      <p className={s.textBlue} key={0}></p>,
                      <span className={s.textBlue} key={1}></span>,
                      <span className={s.textBlue} key={2}></span>,
                      <span className={s.textBlue} key={3}></span>,
                      <span className={s.textBlue} key={4}></span>,
                    ]}
                  />
                </div>
              </Alert>
            </p>
          </div>
        </div>
        <div className={cn(s.formFooterButtons)}>
          <AppButton isOutline onClick={onBack}>
            {t("page.process.form.step.action.back")}
          </AppButton>
          <AppButton onClick={handleShowFinishModal}>
            {t("steps.action.next")}
          </AppButton>
        </div>
      </form>
      <FinishModal
        open={showFinishModal}
        disabledActivate={!isValid}
        onCancel={handleHideFinishModal}
        onActivate={handleSaveActivate}
        onDraft={handleSaveDraft}
        onExit={onExit}
      />
    </>
  );
};
