import { useContext, useState, useEffect, useCallback } from "react";
import { Control } from "react-hook-form";
import { Dropdown, Menu } from "antd";
import cn from "classnames";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { IntroContext } from "../../../../providers/intro-provider/intro.provider";
import { useAppSelector } from "../../../../store/hooks";
import { storagesSelectors } from "../../../../store/storages/storages.selector";
import { selectStorage } from "../../../../store/storages/storage.selector";
import { ESnackbarState } from "../../../../store/types/snackbar.types";
import { IStorageType } from "../../../../store/types/storages.types";
import {
  AppButton,
  EButtonSize,
} from "../../../app-button/app-button.component";
import { AppInput } from "../../../app-input/app-input.component";
import { AppArrowIcon } from "../../../icons/app-arrow.icon";
import { AppImportIcon } from "../../../icons/app-import.icon";
import { AppSearchIcon } from "../../../icons/app-search.icon";

import s from "../app-origin-table.module.scss";

type PropTypes = {
  storageId: number;
  entityId: number;
  isSelectedEntity: boolean;
  searchControl: Control<{ search: string }, object>;
  fileFormat: string;
  onShowUploadCsvModal: () => void;
};

export const OriginTableSearch: React.FC<PropTypes> = ({
  storageId,
  entityId,
  isSelectedEntity,
  searchControl,
  fileFormat,
  onShowUploadCsvModal,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { autoComplete, entityActions } = useContext(IntroContext);
  const [disabledUploadFileBtn, setDisabledUploadFileBtn] = useState(true);

  const { t } = useTranslation();

  const storageData = useAppSelector((state) =>
    storagesSelectors.selectById(state, storageId)
  ) as IStorageType;

  const { currentStorage } = useAppSelector(selectStorage);
  const { storage_import_status, storage_import_type } = currentStorage;

  const displayFileUploadBtn = () => {
    if (fileFormat === "xlsx") {
      setDisabledUploadFileBtn(false);
      return;
    }
    if (
      (storage_import_status === "not_started" && !storage_import_type) ||
      (storage_import_status === "stopped" &&
        storage_import_type === "one_time")
    ) {
      setDisabledUploadFileBtn(false);
    } else {
      setDisabledUploadFileBtn(true);
    }
  };

  useEffect(() => {
    displayFileUploadBtn();
  }, [storage_import_status, storage_import_type]);

  const getMenuItems = useCallback(() => {
    return [
      {
        key: "1",
        disabled: disabledUploadFileBtn,
        label: (
          <span
            className={cn(s.importFileAction, {
              [s.actionDisabled]: !isSelectedEntity,
            })}
            onClick={() => {
              if (disabledUploadFileBtn) return;
              if (!isSelectedEntity) {
                enqueueSnackbar(
                  t("source.detail.card.table.head.sync.choice"),
                  {
                    variant: ESnackbarState.ERROR,
                  }
                );
                return;
              }
              onShowUploadCsvModal();
            }}
          >
            <AppImportIcon width={"20"} height={"20"} />
            {t("source.detail.card.actions.actions_button.upload", {
              fileFormat,
            })}
          </span>
        ),
      },
    ];
  }, [fileFormat, isSelectedEntity, disabledUploadFileBtn]);

  const renderButton = () => {
    return (
      <Dropdown
        overlay={() => <Menu items={getMenuItems()} />}
        placement="bottomRight"
      >
        <div className={s.searchButton} id={"actions-button"}>
          <AppButton
            isOutline
            buttonSize={EButtonSize.HUG}
            sharedStyles={s.inputButton}
          >
            <>
              {t("source.detail.card.actions.actions_button")}
              <AppArrowIcon side={"down"} />
            </>
          </AppButton>
        </div>
      </Dropdown>
    );
  };

  return (
    <div className={s.originTableSearch}>
      <AppInput
        id={"table-search"}
        icon={<AppSearchIcon />}
        sharedStyles={s.searchInput}
        autoComplete={autoComplete}
        name={"search"}
        control={searchControl}
        placeholder={
          t("source.detail.card.placeholder.attribute_search") as string
        }
      />
      {renderButton()}
    </div>
  );
};
