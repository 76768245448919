import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { IStorageEntriesType } from "../../../store/types/storages.types";
import s from "./app-origin-tabs.module.scss";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { AppSearchIcon } from "../../icons/app-search.icon";
import { AppInput } from "../../app-input/app-input.component";
import { useForm } from "react-hook-form";
import { IntroContext } from "../../../providers/intro-provider/intro.provider";
import { AppOriginTab } from "./app-origin-tab/app-origin-tab.component";
import { storageEntitiesSelectors } from "../../../store/storages/storage-entities.selector";
import { useAppSelector } from "../../../store/hooks";
import { intersection } from "../../../helpers/intersection.helper";
import { useTranslation } from "react-i18next";

type PropTypes = {
  modifiedAttributeIds: number[];
};

export const AppOriginTabs: React.FC<PropTypes> = ({
  modifiedAttributeIds,
}) => {
  const { autoComplete } = useContext(IntroContext);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const entityId = Number(query.get("tab"));
  const { t } = useTranslation();

  const storageEntities = useAppSelector(
    storageEntitiesSelectors.selectEntities
  );

  const [search, setSearch] = useState<string>("");
  const tabParent = useRef<HTMLInputElement>(null);
  const entities = Object.values(storageEntities) as IStorageEntriesType[];

  const tabs = search
    ? entities.filter((t: any) =>
        t.entity_name.toLowerCase().includes(search.toLowerCase())
      )
    : entities;

  useEffect(() => {
    if (tabs && !entityId && entities.length) {
      const emptyId = tabs[0]?.id;
      setTimeout(() => {
        onSetQuery(emptyId);
      }, 100);
    }
  }, [tabs, entities]);

  const onSetQuery = (id: number) => {
    navigate({ search: `?tab=${id}` });
  };

  const { control } = useForm({ mode: "onChange" });

  const onChangeSearch = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearch(value);
  };
  const toScroll = useCallback(
    (offsetY?: number) => {
      if (tabParent && offsetY) {
        const tabContainerHeight = tabParent.current!.offsetHeight;
        setTimeout(() => {
          tabParent.current?.scrollTo(0, offsetY - tabContainerHeight);
        }, 100);
      }
    },
    [tabParent]
  );

  const checkUnsavedAttributes = (tab: IStorageEntriesType): boolean => {
    return (
      tab?.attributes &&
      intersection(tab?.attributes as any, modifiedAttributeIds).length > 0
    );
  };

  return (
    <div className={s.originTabsWrapper}>
      <AppInput
        icon={<AppSearchIcon />}
        sharedStyles={s.searchInput}
        onChange={onChangeSearch}
        autoComplete={autoComplete}
        name={"search"}
        control={control}
        placeholder={
          t("source.detail.card.placeholder.entity_search") as string
        }
      />
      <div className={s.originTabList}>
        <div className={s.originTabs} id={"entity-list"} ref={tabParent}>
          {tabs?.map((tab: any, i: number) => {
            return (
              <AppOriginTab
                toScroll={toScroll}
                key={i}
                onSetActive={onSetQuery}
                tabId={tab?.id}
                selectedId={Number(entityId)}
                isSync={tab?.entity_is_selected}
                isUnsaved={checkUnsavedAttributes(tab)}
                name={tab?.entity_name}
              />
            );
          })}
          {!tabs?.length && (
            <div className={s.emptyTabs}>Сущности не найдены</div>
          )}
        </div>
      </div>
    </div>
  );
};
