import React, { useContext, useState } from "react";
import { Collapse, Select, Tooltip, Tree } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";

import { AppInput } from "../../../components/app-input/app-input.component";
import { Alert } from "../../../components/ui/app-alert/app-alert.component";
import { IntroContext } from "../../../providers/intro-provider/intro.provider";
import { AppButton } from "../../../components/app-button/app-button.component";
import { FinishModal } from "./finish-modal/finish-modal.component";
import { AppInfoIcon } from "../../../components/icons/app-info.icon";
import { DataTree } from "./data-tree/data-tree.compnent";
import { EProcessStatus } from "../../../store/types/processes.types";
import { getStorageIdByKey } from "./helper";

import s from "../create-process.module.scss";

const { Panel } = Collapse;
const { Option } = Select;
const { DirectoryTree } = Tree;

const SQL_PLACEHOLDER = "page.process.form.step.three.sql.placeholder";

export const Step3 = ({
  storages,
  processData,
  onSubmit,
  onBack,
  onExit,
}: any) => {
  const rawEntities = processData?.rawEntities || {};
  const storageList = Object.keys(rawEntities).map((key) => {
    const storageId = getStorageIdByKey(key);
    const storage = storages.find(
      ({ id }: { id: number }) => Number(id) === Number(storageId)
    );
    const storageCopy = JSON.parse(JSON.stringify(storage)); // Error: object is not extensible
    storageCopy.selectedEntities = storage.entities.filter((entity: any) =>
      processData.rawEntities[key].includes(entity.id)
    );

    return storageCopy;
  });
  const { t } = useTranslation();
  const [showFinishModal, setShowFinishModal] = useState(false);
  const { autoComplete } = useContext(IntroContext);
  const formInstance = useForm({
    mode: "onChange",
    defaultValues: {
      process_sql: processData.process_sql,
      source: storageList[0]?.id || undefined,
    },
  });

  const {
    control,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = formInstance;

  const handleWriteSqlData = (data: string) => {
    const previousValue = getValues("process_sql");
    setValue("process_sql", `${previousValue + " " + data}`);
  };

  const handleShowFinishModal = () => {
    setShowFinishModal(true);
  };

  const handleHideFinishModal = () => {
    setShowFinishModal(false);
  };

  const handleSaveActivate = () => {
    const process_sql = getValues("process_sql");
    onSubmit({
      process_sql,
      process_status: EProcessStatus.ACTIVE,
    });
  };

  const handleSaveDraft = () => {
    const process_sql = getValues("process_sql");
    onSubmit({
      process_sql,
      process_status: EProcessStatus.DRAFT,
    });
  };

  return (
    <>
      <form className={cn(s.formWrapper, s.formWrapperLast)}>
        <div className={s.formTitleContainer}>
          <h3 className={s.formTitle}>
            {t("page.process.form.step.enter_request")}
            <Tooltip
              placement={"topLeft"}
              title={t("page.process.form.step.enter_request.tooltip")}
              color={"var(--white)"}
            >
              <div className={s.helpIconContainer}>
                <AppInfoIcon sharedStyles={s.helpIcon} />
              </div>
            </Tooltip>
          </h3>
        </div>
        <div className={s.stepTwoContainer}>
          <div className={s.searchWrapper}>
            <DataTree
              storageList={storageList}
              handleWriteSqlData={handleWriteSqlData}
              control={control}
            />
          </div>

          <div className={s.stepTwoEntities}>
            <AppInput
              sharedStyles={s.sqlTextArea}
              rules={{
                required: t(
                  "page.process.form.step.validation.required"
                ) as string,
              }}
              errors={errors}
              control={control}
              name={"process_sql"}
              placeholder={t(SQL_PLACEHOLDER) as string}
              isTextArea={true}
            />
            <p className={s.helpSql}>
              <Alert title={"page.process.form.step.three.hint"}>
                <div>
                  <Trans
                    t={t}
                    i18nKey={"page.process.form.step.three.description"}
                    components={[
                      <p className={s.textBlue} key={0}></p>,
                      <span className={s.textBlue} key={1}></span>,
                      <span className={s.textBlue} key={2}></span>,
                      <span className={s.textBlue} key={3}></span>,
                      <span className={s.textBlue} key={4}></span>,
                    ]}
                  />
                </div>
              </Alert>
            </p>
          </div>
        </div>
        <div className={cn(s.formFooterButtons)}>
          <AppButton isOutline onClick={onBack}>
            {t("page.process.form.step.action.back")}
          </AppButton>
          <AppButton onClick={handleShowFinishModal}>
            {t("steps.action.next")}
          </AppButton>
        </div>
      </form>
      <FinishModal
        open={showFinishModal}
        disabledActivate={!isValid}
        onCancel={handleHideFinishModal}
        onActivate={handleSaveActivate}
        onDraft={handleSaveDraft}
        onExit={onExit}
      />
    </>
  );
};

interface ISqlButtonPropsType {
  onCLick: (data: string) => void;
  data: string;
  tooltipLabel?: string;
  tooltipDesc?: string;
}

const SqlBtn: React.FC<ISqlButtonPropsType> = ({ onCLick, data }) => {
  return (
    <div
      className={s.sqlBtn}
      onClick={(e) => {
        e.stopPropagation();
        onCLick(data);
      }}
    >
      SQL <PlusOutlined className={s.icon} />
    </div>
  );
};
