import React, { useCallback, useEffect, useMemo } from "react";
import { RegisterOptions, useForm, Controller } from "react-hook-form";
import { Checkbox, Radio, Select, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectAllProcesses } from "../../../store/processes/process-list/processes.selector";
import { AppScheduleIcon } from "../../../components/icons/app-schedule.icon";
import { AppInfoIcon } from "../../../components/icons/app-info.icon";
import { plural } from "../../../helpers/plural.helper";
import { EInterval, intervalMapper } from "../process.helper";

import { AppInput } from "../../../components/app-input/app-input.component";
import { AppRadioGroup } from "../../../components/app-radio/app-radio.component";
import { AppButton } from "../../../components/app-button/app-button.component";
import { fetchProcesses } from "../../../api/processes/processes-actions.api";
import { AppTabCalendar } from "../../../components/app-tab-calendar/app-tab-calendar.component";

import s from "../create-process.module.scss";

const scheduleOptions = [
  {
    key: intervalMapper(EInterval.PER_HOUR),
    value: "page.process.form.step.one.schedule.option.hour",
    engKey: "perhour",
  },
  {
    key: intervalMapper(EInterval.PER_DAY),
    value: "page.process.form.step.one.schedule.option.day",
    engKey: "perday",
  },
  {
    key: intervalMapper(EInterval.PER_WEEK),
    value: "page.process.form.step.one.schedule.option.week",
    engKey: "perweek",
  },
  {
    key: intervalMapper(EInterval.PER_MONTH),
    value: "page.process.form.step.one.schedule.option.month",
    engKey: "permonth",
  },
];

const { Option } = Select;

export const Step1 = ({
  processData,
  isLowcode,
  onUpdate,
  setTimestamp,
  onChangeStepData,
}: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { entries, search, loading, error } =
    useAppSelector(selectAllProcesses);

  const formInstance = useForm({
    mode: "onChange",
    defaultValues: {
      process_name: processData.process_name,
      process_description: processData.process_description,
      process_scheduler: processData.process_scheduler,
      process_is_overwriting: processData.process_is_overwriting,
      onSchedule: processData.onSchedule,
      interval: intervalMapper(processData.interval) || processData.interval,
    },
  });

  useEffect(() => {
    dispatch(fetchProcesses());
  }, []);

  const {
    control,
    trigger,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
    clearErrors,
  } = formInstance;

  const validateSymbols = (value: string): string | boolean => {
    const regex = new RegExp(/[^a-zA-ZЁёА-я0-9\s]/g);
    return (
      !value.match(regex) ||
      (t("page.process.form.step.validation.spec_symbols") as string)
    );
  };
  const validateSameName = (value: string): string | boolean => {
    const connectionNames = entries.map((process) =>
      process.process_name.toLowerCase()
    );

    return (
      !connectionNames.includes(value.toLowerCase()) ||
      (t("page.process.form.step.validation.has") as string)
    );
  };

  const nameValidation: RegisterOptions = {
    required: t("page.process.form.step.validation.required") as string,
    maxLength: {
      value: 30,
      message: t("page.process.form.step.validation.length.30") as string,
    },
    validate: {
      validateSymbols,
      validateSameName,
    },
  };

  const submitForm = (data: any) => {
    const { process_scheduler, interval } = data;
    const schedule = { ...process_scheduler, interval };
    setTimestamp(schedule);
    onUpdate(data);
  };

  const scheduler = watch("onSchedule");
  const timeType = watch("interval");
  const watchStepData = watch([
    "process_name",
    "process_description",
    "process_scheduler",
    "interval",
    "onSchedule",
  ]);

  useEffect(() => {
    onChangeStepData(watchStepData);
  }, [watchStepData]);

  const resetField = () => {
    setValue("process_scheduler", {
      minute: null,
      hour: null,
      week: null,
      month: null,
    });
    clearErrors();
  };

  const changeSheduleRadioGroup = () => {
    trigger("onSchedule");
  };

  return (
    <form
      className={cn(s.formWrapper, s.stepOneForm)}
      onSubmit={handleSubmit((data) => submitForm(data))}
    >
      <div className={s.formSection}>
        <div className={s.formTitleContainer}>
          <h3 className={s.formTitle}>
            {t("page.process.form.step.description")}
            <Tooltip
              placement={"topLeft"}
              title={t("page.process.form.step.basic_details.tooltip")}
              color={"var(--white)"}
            >
              <div className={s.helpIconContainer}>
                <AppInfoIcon sharedStyles={s.helpIcon} />
              </div>
            </Tooltip>
          </h3>
        </div>
        <div className={s.formFields}>
          <AppInput
            rules={nameValidation}
            errors={errors}
            control={control}
            name={"process_name"}
            placeholder={t("page.process.form.step.process_name") as string}
          />
          <AppInput
            rules={{
              maxLength: {
                value: 300,
                message: t(
                  "page.process.form.step.one.description.validation300"
                ),
              },
            }}
            errors={errors}
            control={control}
            name={"process_description"}
            placeholder={t("page.process.form.step.description") as string}
            isTextArea={true}
          />
        </div>
      </div>
      {isLowcode ? null : (
        <div className={s.formSection}>
          <div className={s.formTitleContainer}>
            <h3 className={s.formTitle}>
              {t("page.process.form.step.one.additional")}
            </h3>
          </div>
          <div className={s.formFields}>
            <Controller
              name="process_is_overwriting"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox onChange={onChange} checked={value}>
                  {t("page.process.form.step.one.delete_notification")}
                </Checkbox>
              )}
            />
          </div>
        </div>
      )}
      <div className={s.formFooterButtons}>
        <div></div>
        <AppButton disabled={!isValid} htmlType={"submit"}>
          {t("steps.action.next")}
        </AppButton>
      </div>
    </form>
  );
};

const ScheduleInputs = ({ control, timeType, errors, watch, required }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const engTimeType = useMemo(
    () => scheduleOptions.find((item) => item.key === timeType)?.engKey,
    [timeType]
  );

  const itemsDays = [
    {
      key: "1",
      value: t("form.schedule.days.pn"),
      full: t("form.schedule.days.pn.full"),
    },
    {
      key: "2",
      value: t("form.schedule.days.vt"),
      full: t("form.schedule.days.vt.full"),
    },
    {
      key: "3",
      value: t("form.schedule.days.sr"),
      full: t("form.schedule.days.sr.full"),
    },
    {
      key: "4",
      value: t("form.schedule.days.cht"),
      full: t("form.schedule.days.cht.full"),
    },
    {
      key: "5",
      value: t("form.schedule.days.pt"),
      full: t("form.schedule.days.pt.full"),
    },
    {
      key: "6",
      value: t("form.schedule.days.sb"),
      full: t("form.schedule.days.sb.full"),
    },
    {
      key: "0",
      value: t("form.schedule.days.vs"),
      full: t("form.schedule.days.vs.full"),
    },
  ];

  const itemsMonths = new Array(31).fill(0).map((el, i) => {
    const reducer = `${i + 1}`;
    return {
      key: reducer,
      value: reducer,
    };
  });

  const PROCESS_SCHEDULER_MINUTE = "process_scheduler.minute";
  const PROCESS_SCHEDULER_HOUR = "process_scheduler.hour";
  const PROCESS_SCHEDULER_WEEK = "process_scheduler.week";
  const PROCESS_SCHEDULER_MONTH = "process_scheduler.month";
  const MINUTE_TRANSLATE_KEY = "page.process.form.step.one.schedule.minutes";
  const HOUR_TRANSLATE_KEY = "page.process.form.step.one.schedule.hours";

  const minute = watch(PROCESS_SCHEDULER_MINUTE);
  const hour = watch(PROCESS_SCHEDULER_HOUR);
  const week = watch(PROCESS_SCHEDULER_WEEK);
  const month = watch(PROCESS_SCHEDULER_MONTH);

  const minValidation: RegisterOptions = {
    min: {
      value: 0,
      message: "0 - 59",
    },
    max: {
      value: 59,
      message: "0 - 59",
    },
    required,
  };

  const hourValidation: RegisterOptions = {
    min: {
      value: 0,
      message: "0 - 23",
    },
    max: {
      value: 23,
      message: "0 - 23",
    },
    required,
  };

  const monthAndWeekValidation: RegisterOptions = {
    required,
  };

  const pluralMinute = (value: number) => {
    return plural(
      value,
      "form.schedule.minute.one",
      "form.schedule.minute.two",
      "form.schedule.minute.five"
    );
  };
  const pluralWeek = (key: string) => {
    if ([1, 2, 4].includes(Number(key))) {
      return "form.schedule.each.two";
    }
    if ([3, 5, 6].includes(Number(key))) {
      return "form.schedule.each.one";
    }
    return "form.schedule.each.five";
  };

  const checkNumber = (value: string) => {
    return value.length >= 2 ? value : `0${value}`;
  };

  const findWeek = itemsDays.find((day) => day.key === week)?.full || "";
  // const findMonth = itemsMonths.find((week) => week.key === month)?.full || "";

  const renderPrompt = useCallback(() => {
    if (Object.values(errors).length) {
      return (
        <span className={cn(s.promptValue, s._error)}>
          {t("page.process.form.step.one.schedule.wrong_time")}
        </span>
      );
    }

    switch (engTimeType as EInterval) {
      case EInterval.PER_HOUR:
        if (minute) {
          return (
            <span className={s.promptValue}>
              <AppScheduleIcon height={"50"} />{" "}
              {t("form.schedule.option.result.minutes", {
                0: minute,
                1: t("form.schedule.minute.one"),
              })}
            </span>
          );
        }
        break;
      case EInterval.PER_DAY:
        if (hour && minute) {
          return (
            <span className={s.promptValue}>
              <AppScheduleIcon height={"50"} />{" "}
              {t("form.schedule.option.result.hours", {
                0: checkNumber(hour),
                1: checkNumber(minute),
              })}
            </span>
          );
        }
        break;
      case EInterval.PER_WEEK:
        if (hour && minute && week) {
          return (
            <span className={s.promptValue}>
              <AppScheduleIcon height={"50"} />{" "}
              {t("form.schedule.option.result.weeks", {
                0: t(pluralWeek(week)),
                1: findWeek,
                2: checkNumber(hour),
                3: checkNumber(minute),
              })}
            </span>
          );
        }
        break;
      case EInterval.PER_MONTH:
        if (hour && minute && month) {
          return (
            <span className={s.promptValue}>
              <AppScheduleIcon height={"50"} />{" "}
              {t("form.schedule.option.result.months", {
                0: month,
                1: checkNumber(hour),
                2: checkNumber(minute),
              })}
            </span>
          );
        }
        break;
    }
  }, [engTimeType, minute, hour, week, month, errors]);

  return (
    <>
      {engTimeType === "perhour" && (
        <div className={s.intervalInputs}>
          <AppInput
            label={t(MINUTE_TRANSLATE_KEY) as string}
            rules={minValidation}
            type={"number"}
            sharedStyles={cn(s.intervalInput, s._small)}
            errors={errors}
            control={control}
            name={PROCESS_SCHEDULER_MINUTE}
            placeholder={"00"}
          />
          {renderPrompt()}
        </div>
      )}
      {engTimeType === "perday" && (
        <div className={s.intervalInputs}>
          <AppInput
            label={t(HOUR_TRANSLATE_KEY) as string}
            rules={hourValidation}
            sharedStyles={cn(s.intervalInput, s._small)}
            type={"number"}
            errors={errors}
            control={control}
            name={PROCESS_SCHEDULER_HOUR}
            placeholder={"00"}
          />
          <span style={{ lineHeight: "50px" }}>:</span>
          <AppInput
            label={t(MINUTE_TRANSLATE_KEY) as string}
            rules={minValidation}
            sharedStyles={cn(s.intervalInput, s._small)}
            type={"number"}
            errors={errors}
            control={control}
            name={PROCESS_SCHEDULER_MINUTE}
            placeholder={"00"}
          />
          {renderPrompt()}
        </div>
      )}
      {engTimeType === "perweek" && (
        <div className={cn(s.intervalInputs, s._month)}>
          <AppTabCalendar
            control={control}
            name={"process_scheduler.week"}
            items={itemsDays}
            label={t("page.process.form.step.one.schedule.week") as string}
            rules={monthAndWeekValidation}
          />
          <div className={s.intervalInputs}>
            <AppInput
              label={t(HOUR_TRANSLATE_KEY) as string}
              type={"number"}
              rules={hourValidation}
              sharedStyles={cn(s.intervalInput, s._small)}
              errors={errors}
              control={control}
              name={PROCESS_SCHEDULER_HOUR}
              placeholder={"00"}
            />
            <span style={{ lineHeight: "50px" }}>:</span>
            <AppInput
              label={t(MINUTE_TRANSLATE_KEY) as string}
              rules={minValidation}
              type={"number"}
              sharedStyles={cn(s.intervalInput, s._small)}
              errors={errors}
              control={control}
              name={PROCESS_SCHEDULER_MINUTE}
              placeholder={"00"}
            />
            {renderPrompt()}
          </div>
        </div>
      )}
      {engTimeType === "permonth" && (
        <div className={cn(s.intervalInputs, s._month)}>
          <AppTabCalendar
            control={control}
            name={"process_scheduler.month"}
            items={itemsMonths}
            label={t("page.process.form.step.one.schedule.month") as string}
            rules={monthAndWeekValidation}
          />
          <div className={s.intervalInputs}>
            <AppInput
              label={t(HOUR_TRANSLATE_KEY) as string}
              rules={hourValidation}
              sharedStyles={cn(s.intervalInput, s._small)}
              errors={errors}
              type={"number"}
              control={control}
              name={PROCESS_SCHEDULER_HOUR}
              placeholder={"ОО"}
            />
            <span style={{ lineHeight: "50px" }}>:</span>
            <AppInput
              label={t(MINUTE_TRANSLATE_KEY) as string}
              rules={minValidation}
              sharedStyles={cn(s.intervalInput, s._small)}
              errors={errors}
              type={"number"}
              control={control}
              name={PROCESS_SCHEDULER_MINUTE}
              placeholder={"ОО"}
            />
            {renderPrompt()}
          </div>
        </div>
      )}
    </>
  );
};
