import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../../store/axios-instanse";
import { showMessage } from "../../store/snackbar/snackbar.slice";
import { ESnackbarState } from "../../store/types/snackbar.types";

export const fetchProcesses = createAsyncThunk("process/fetch", async () => {
  try {
    const response = await axios.get(
      "/dlh-backend/processes/get_list_without_lineage/"
    );
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
});

type ShortProcessFilterType = {
  limit: number;
  offset: number;
  short?: boolean;
  process_status?: string;
  query?: string;
};

export const fetchShortProcesses = createAsyncThunk(
  "process/short/fetch",
  async (params: ShortProcessFilterType, { rejectWithValue, dispatch }) => {
    try {
      return axios({
        method: "GET",
        url: "/dlh-backend/processes/get_list_without_lineage/",
        params,
      }).then(({ data }) => {
        if (!data.results) throw new Error("getting processes error");
        return data;
      });
    } catch (error) {
      dispatch(
        showMessage({ message: error.message, type: ESnackbarState.ERROR })
      );
      return rejectWithValue(error.message);
    }
  }
);

const ERROR_TEXT = "notification.error";
const ERROR_SQL_TEXT = "page.process.sql.query.error";

export const editProcess = createAsyncThunk(
  "process/edit",
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "PUT",
        url: `/dlh-backend/processes/${payload.id}/edit/`,
        data: payload,
      });

      dispatch(
        showMessage({
          message: "page.process.form.step.three.edit.success",
          type: ESnackbarState.SUCCESS,
        })
      );

      dispatch(fetchProcesses());
      return response.data;
    } catch (error) {
      const code = error.response.data.code;
      if (code === "sql_code_invalid") {
        dispatch(
          showMessage({
            message: ERROR_SQL_TEXT,
            type: ESnackbarState.ERROR,
          })
        );
        return error.response.data;
      }
      const _errors = error.response.data.errors;
      const errorKeys = Object.keys(_errors);
      if (errorKeys.length) {
        errorKeys.forEach((key) => {
          dispatch(
            showMessage({
              message: _errors[key].join(", "),
              type: ESnackbarState.ERROR,
            })
          );
        });
      } else {
        dispatch(
          showMessage({
            message: "page.process.form.step.three.edit.error",
            type: ESnackbarState.ERROR,
          })
        );
      }

      return rejectWithValue(error.response.status);
    }
  }
);

export const createProcess = createAsyncThunk(
  "process/create",
  async (processData: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: "/dlh-backend/processes/process_create/",
        data: processData,
      });

      dispatch(
        showMessage({
          message: "page.process.form.step.three.success",
          type: ESnackbarState.SUCCESS,
        })
      );
      dispatch(fetchProcesses());
      return response.data;
    } catch (error) {
      const code = error.response.data.code;
      if (code === "sql_code_invalid") {
        dispatch(
          showMessage({
            message: ERROR_SQL_TEXT,
            type: ESnackbarState.ERROR,
          })
        );
        return error.response.data;
      }
      const _errors = error.response.data.errors;
      const errorKeys = Object.keys(_errors);
      if (errorKeys.length) {
        errorKeys.forEach((key) => {
          dispatch(
            showMessage({
              message: _errors[key].join(", "),
              type: ESnackbarState.ERROR,
            })
          );
        });
      } else {
        dispatch(
          showMessage({
            message: "page.process.form.step.three.error",
            type: ESnackbarState.ERROR,
          })
        );
      }

      return rejectWithValue(error.response.status);
    }
  }
);

export const processGenerateSql = createAsyncThunk(
  "process/generateSql",
  async (tree: any, { rejectWithValue, dispatch }) =>
    axios
      .post("/dlh-backend/processes/generate_sql/", { tree })
      .then(({ data }) => data)
      .catch((error) => {
        const code = error.response.data.code;
        if (code === "sql_code_invalid") {
          dispatch(
            showMessage({
              message: ERROR_SQL_TEXT,
              type: ESnackbarState.ERROR,
            })
          );
        } else {
          dispatch(
            showMessage({
              message: ERROR_TEXT,
              type: ESnackbarState.ERROR,
            })
          );
        }

        return rejectWithValue(error.response);
      })
);

export const archiveProcessAction = createAsyncThunk(
  "process/archive",
  async (
    { id, process_is_deleted }: { id: number; process_is_deleted: boolean },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios({
        method: "POST",
        url: `/dlh-backend/processes/${id}/delete/`,
        data: { process_is_deleted },
      });

      dispatch(
        showMessage({
          message: process_is_deleted
            ? "page.process.card.actions.archive.message"
            : "page.process.card.actions.unarchive.message",
          type: ESnackbarState.SUCCESS,
        })
      );
      dispatch(fetchProcesses());
      return { id, process_is_deleted };
    } catch (error) {
      dispatch(
        showMessage({
          message: ERROR_TEXT,
          type: ESnackbarState.ERROR,
        })
      );
      return rejectWithValue(error.response.status);
    }
  }
);

export const runProcessAction = createAsyncThunk(
  "run/archive",
  async (
    { id, process_name_slug }: { id: number; process_name_slug: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios({
        method: "GET",
        url: `/dlh-backend/processes/${id}/run/`,
      });

      dispatch(
        showMessage({
          message: "page.process.card.actions.message.start",
          type: ESnackbarState.SUCCESS,
        })
      );
      dispatch(fetchProcesses());
      return { id, process_name_slug };
    } catch (error) {
      if (error.response.status === 404) {
        dispatch(
          showMessage({
            message: "page.process.card.actions.message.start.dag",
            transOptions: {
              0: process_name_slug,
            },
            type: ESnackbarState.ERROR,
          })
        );
      } else {
        dispatch(
          showMessage({
            message: ERROR_TEXT,
            type: ESnackbarState.ERROR,
          })
        );
      }
      return rejectWithValue(error.response.status);
    }
  }
);

export const fetchFullProcessById = createAsyncThunk(
  "process/full",
  async (id: number) => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `/dlh-backend/processes/${id}/?full=true`,
      });
      return data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchProcessById = createAsyncThunk(
  "process",
  (id: number, { rejectWithValue, dispatch }) =>
    axios
      .get(`/dlh-backend/processes/${id}/`)
      .then(({ data }) => data)
      .catch((error) => {
        dispatch(
          showMessage({
            message: error.response.data.message,
            type: ESnackbarState.ERROR,
          })
        );
        return rejectWithValue(error.response.status);
      })
);

export const fetchViewProcesses = createAsyncThunk(
  "process/view",
  async (id: number) => {
    try {
      const response = await axios({
        method: "GET",
        url: `/dlh-backend/processes/${id}/view/`,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
