import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  getConnectedEdges,
  updateEdge,
  addEdge,
  getOutgoers,
} from "reactflow";
import type {
  Node,
  Edge,
  XYPosition,
  ReactFlowInstance,
  EdgeChange,
  Connection,
} from "reactflow";
import { useTranslation } from "react-i18next";

import { AppButton } from "../../../components/app-button/app-button.component";
import {
  INodeTreeTypes,
  IStorageType,
} from "../../../store/types/storages.types";
import {
  StorageNode,
  MergeTransformNode,
  ProcessNode,
} from "../../../components/custom-model-node";
import { AppLogoLoader } from "../../../components/ui/app-animated-logo/app-animated-logo.component";

import s from "../../create-process/steps/low-code.module.scss";
import { Schema } from "../../create-process/schema";
import LowCodeMergeModal from "../../create-process/steps/low-code-merge-modal/low-code-merge-modal.component";
import {
  MergeDataType,
  ProcessType,
  ShortProcessType,
} from "../../../store/types/low-code.types";
import { TransformContext } from "../../create-process/steps/transform.provider";
import { AppConfirmModal } from "../../../components/ui/app-confirm-modal/app-confirm-modal.component";
import { FinishModal } from "../../create-process/steps/finish-modal/finish-modal.component";
import { EProcessStatus } from "../../../store/types/processes.types";
import { LowCodeViewProcessModal } from "../../../components/low-code/modal/process-view/process-view.component";
import { LowCodeSelectEntityModal } from "../../create-process/steps/low-code-select-entity-modal/low-code-select-entity-modal.component";
import { LowCodeEditEntityModal } from "../../create-process/steps/low-code-edit-entity-modal/low-code-edit-entity-modal.component";
import { Collapse } from "antd";
import { AppArrowIcon } from "../../../components/icons";
import {
  ProcessesList,
  StoragesList,
  TransformationsList,
} from "../../../components/low-code/aside";
import { GenerateSqlDrawer } from "../../create-process/steps/generate-sql-drawer/generate-sql-drawer.component";

type PropTypes = {
  processData: any;
  storages: IStorageType[];
  onBack: () => void;
  onSubmit: (data: any) => void;
  onExit: () => void;
};

const nodeTypes = {
  storageNode: StorageNode,
  processNode: ProcessNode,
  mergeTransformNode: MergeTransformNode,
};

export const StepEditLowCodePlace: React.FC<PropTypes> = ({
  processData,
  storages,
  onBack,
  onSubmit,
  onExit,
}) => {
  const { t } = useTranslation();
  const edgeUpdateSuccessful = useRef(true);
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [nodeSelected, setNodeSelected] = useState<any>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState<Array<INodeTreeTypes>>(
    processData?.process_schema?.rf?.nodes
      ? processData?.process_schema?.rf?.nodes.map((nd) =>
          JSON.parse(JSON.stringify(nd))
        )
      : []
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    processData?.process_schema?.rf?.edges ?? []
  );
  const [schema, setSchema] = useState<Schema>();
  const [mergeNode, setMergeNode] = useState<Node>();
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [confirmRemoveNode, setConfirmRemoveNode] = useState<string | null>(
    null
  );
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [confirmRemoveEdges, setConfirmRemoveEdges] = useState<Edge[] | null>(
    null
  );
  const [confirmEdgeUpdate, setConfirmEdgeUpdate] = useState<{
    edge: Edge;
    connection: Connection;
  } | null>(null);
  const [processView, showProcessView] = useState<any>(null);
  const [entitySelectModal, showEntitySelectModal] = useState<any>(null);
  const [entityEditModal, showEntityEditModal] = useState<any>(null);

  useEffect(() => {
    if (nodes.some((node) => node.selected) && nodeSelected === null) {
      cleanSelectedNodes();
    }
  }, [nodeSelected]);

  const removedNodeText = useMemo(() => {
    const node = nodes.find(
      ({ id }) => String(id) === String(confirmRemoveNode)
    ) as Node;

    if (!node) return null;

    if (node.type === "mergeTransformNode") {
      return t(
        "page.process.form.step.lowcode.nodes.remove.transform.confirm.body"
      );
    }

    return t("page.process.form.step.lowcode.nodes.remove.confirm.body", {
      nodeName: node.data.title,
    });
  }, [confirmRemoveNode]);

  const handleFlowWrapperClick = useCallback(() => {
    setNodeSelected(null);
  }, []);

  const handleEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const handleEdgeUpdate = (edge: Edge, connection: Connection) => {
    edgeUpdateSuccessful.current = true;

    if (schema?.find(edge.target)) {
      setConfirmEdgeUpdate({ edge, connection });
    } else {
      setEdges((els) => updateEdge(edge, connection, els));
    }
  };

  const handleEdgeUpdateEnd = (_: MouseEvent | TouchEvent, edge: Edge) => {
    if (edgeUpdateSuccessful.current) {
      return;
    }

    if (schema?.find(edge.target)) {
      setConfirmRemoveEdges([edge]);
    } else {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  };

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const handleDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");
      const position = calculateDropPosition(event);

      if (type === "processNode") {
        const process = event.dataTransfer.getData("application/process");
        showProcessView({
          ...JSON.parse(process),
          position,
        });
      }

      if (type === "storageNode") {
        const nodeId = event.dataTransfer.getData("application/nodeId");
        const storage = storages.find(
          ({ id }) => Number(nodeId) === Number(id)
        );

        // check if the dropped element is valid
        if (typeof type === "undefined" || !type || !storage) {
          return;
        }

        showEntitySelectModal({ ...storage, type, position });
      }

      if (type === "mergeTransformNode") {
        reactFlowInstance.addNodes([createMergeNode(position)]);
      }
    },
    [reactFlowInstance, storages]
  );

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: "smoothstep",
            style: { strokeWidth: "1px" },
            pathOptions: { borderRadius: 100 },
            className: "normal-edge",
          },
          eds
        )
      );
    },
    [edges]
  );

  const lowCodeMergeModal = useCallback(() => {
    if (mergeNode) {
      const transformation = mergeNode?.data.index
        ? schema?.find(`merge_${mergeNode?.data.index}`)?.toObject()
        : null;

      return (
        <LowCodeMergeModal
          rf={reactFlowInstance}
          schema={schema}
          transformNode={mergeNode}
          transformation={transformation}
          onSubmit={handleMergeSubmit}
          onCancel={() => setMergeNode(undefined)}
        />
      );
    }
  }, [mergeNode]);

  const createProcessNode = (
    shortProcess: ShortProcessType,
    fullProcess: ProcessType
  ) => {
    const id = shortProcess.id.toString();

    return {
      id,
      type: "processNode",
      position: shortProcess.position,
      data: {
        id,
        title: shortProcess.name,
        status: shortProcess.status,
        schedule: fullProcess.schedule,
        entity: fullProcess.entity,
      },
    };
  };

  const createStorageNode = (storage: any): Node => {
    const id = storage.id.toString();

    return {
      id,
      type: storage.type,
      position: storage.position,
      data: {
        id,
        title: storage.storage_name,
        fields: storage.entities.map((entity) => {
          return {
            id: String(entity.id),
            name: entity.entity_name,
          };
        }),
      },
    };
  };

  const createMergeNode = (position: XYPosition) => {
    const maxIndex = reactFlowInstance
      .getNodes()
      .filter(({ type }) => type === "mergeTransformNode")
      .reduce(
        (prevIndex: number, cur: Node) =>
          cur.data?.index > prevIndex ? cur.data.index : prevIndex,
        reactFlowInstance.mergeIndex || 0
      );

    const index = maxIndex + 1;
    reactFlowInstance.mergeIndex = index;

    return {
      id: `merge_${index}`,
      type: "mergeTransformNode",
      position,
      data: { index },
    };
  };

  const findNode = (nodeId: string): Node => reactFlowInstance.getNode(nodeId);

  const parentTransformKey = (mergeData: MergeDataType) => {
    const parentIncomer = mergeData.incomers.find(({ storage }) =>
      String(storage.id).includes("merge")
    );
    return parentIncomer ? parentIncomer.storage.id : null;
  };

  const handleMergeSubmit = (mergeData: MergeDataType) => {
    schema?.add(mergeData, null);
  };

  const handleBack = () => {
    onBack();
  };

  const submitSelectedEntities = (storage: any) => {
    const newNode = createStorageNode(storage);
    setNodes((nds) => nds.concat(newNode as any));
  };

  const handleCloseEntitySelectModal = () => showEntitySelectModal(null);

  const handleSubmit = (process_status: EProcessStatus) => {
    onSubmit({
      ...schema?.process,
      process_sql: null,
      process_scheduler: null,
      process_is_overwriting: true,
      process_status,
      entities: schema?.getEntities(),
      rf: reactFlowInstance.toObject(),
      tree: schema?.toObject(),
    });
  };

  const cleanSelectedNodes = () => {
    const cleanNodes = nodes.map((nodeElem) => {
      return {
        ...nodeElem,
        selected: false,
      };
    });
    setNodes(cleanNodes);
  };

  if (!storages) {
    return (
      <div className={s.container}>
        <AppLogoLoader loading={true} />
      </div>
    );
  }

  const snapGrid: [number, number] = [16, 16];

  const handleDragStart = (
    event: React.DragEvent,
    nodeType: string,
    nodeId?: string | number
  ) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/nodeId", String(nodeId));
    event.dataTransfer.effectAllowed = "move";
  };

  const isNodeIntersecting = (nodeA, nodeB): boolean => {
    const padding = 12;

    const aLeft = nodeA.position.x - padding;
    const aRight = nodeA.position.x + nodeA.width + padding;
    const aTop = nodeA.position.y - padding;
    const aBottom = nodeA.position.y + nodeA.height + padding;

    const bLeft = nodeB.position.x - padding;
    const bRight = nodeB.position.x + nodeB.width + padding;
    const bTop = nodeB.position.y - padding;
    const bBottom = nodeB.position.y + nodeB.height + padding;

    return aLeft < bRight && aRight > bLeft && aTop < bBottom && aBottom > bTop;
  };

  const repositionNode = (nodes, nodeToReposition) => {
    return nodes.map((node) => {
      if (node.id === nodeToReposition.id) {
        const newPosition = { ...node.position };

        for (const otherNode of nodes) {
          if (
            otherNode.id !== nodeToReposition.id &&
            isNodeIntersecting(node, otherNode)
          ) {
            newPosition.y += otherNode.height + 20;
          }
        }

        return {
          ...node,
          position: newPosition,
        };
      }

      return node;
    });
  };

  const onNodeDragStop = (event, node) => {
    const updatedNodes = repositionNode(nodes, node);
    setNodes(updatedNodes);
  };

  const handleNodeClick = (event: React.MouseEvent, node: any) => {
    event.stopPropagation();

    if (node.type === "storageNode") {
      const storage =
        storages.find((storage) => Number(storage.id) === Number(node.id)) ||
        null;
      const nodeFieldsIds = node.data.fields.map((field) => Number(field.id));

      const selected = { ...storage, type: node.type };
      selected.entities = selected?.entities?.filter((entity) =>
        nodeFieldsIds.includes(Number(entity.id))
      );
      setNodeSelected(selected);
    } else if (node.type === "processNode") {
      setNodeSelected(node);
    } else {
      setNodeSelected(null);
    }
  };

  const calculateDropPosition = ({ clientX, clientY }): XYPosition => {
    const reactFlowBounds = reactFlowWrapper.current
      ? reactFlowWrapper.current.getBoundingClientRect()
      : { left: 0, top: 0 };

    return reactFlowInstance.project({
      x: clientX - reactFlowBounds.left,
      y: clientY - reactFlowBounds.top,
    });
  };

  const handleEdgeChange = (changes: EdgeChange[]) => {
    onEdgesChange(changes.filter(({ type }) => type !== "remove"));
  };

  const onEdgesDelete = (edges: Edge[]): void => {
    const changes: EdgeChange[] = [];

    edges.forEach(({ id, target }) => {
      if (!confirmRemoveNode && schema?.find(target)) {
        setConfirmRemoveEdges(edges);
      } else {
        changes.push({ id, type: "remove" });
      }
    });

    onEdgesChange(changes);
  };

  const findTransformKeyByNode = (node: Node): string | undefined => {
    if (node.type === "mergeTransformNode") {
      return node.id;
    }

    const transform = getOutgoers(node, nodes, edges).find(
      ({ type }) => type === "mergeTransformNode"
    );

    return transform?.id;
  };

  return (
    <>
      <div className={s.container}>
        <TransformContext.Provider
          value={{
            onChangeSettings: (nodeId: string) => {
              setMergeNode(findNode(nodeId));
            },
            onEmitDeleteNode: (nodeId: string) => {
              const node = findNode(nodeId);
              const connectedEdges = getConnectedEdges([node], edges);

              if (node.type === "mergeTransformNode" && !schema) {
                reactFlowInstance.deleteElements({ nodes: [node] });
                return;
              }

              if (connectedEdges.length) {
                setConfirmRemoveNode(nodeId);
                return;
              }

              reactFlowInstance.deleteElements({ nodes: [node] });
            },
            onEmitEditNode: (nodeId: string | null) => {
              showEntityEditModal({
                storage: storages.find(
                  ({ id }) => Number(id) === Number(nodeId)
                ),
                node: nodes.find(({ id }) => Number(id) === Number(nodeId)),
              });
            },
          }}
        >
          <ReactFlowProvider>
            <div
              className={s.wrapper}
              ref={reactFlowWrapper}
              onClick={() => handleFlowWrapperClick()}
            >
              <ReactFlow
                id={"process-low-code-area"}
                fitViewOptions={{ maxZoom: 700, padding: 1 }}
                nodes={nodes}
                snapToGrid
                snapGrid={snapGrid}
                onNodeClick={handleNodeClick}
                onNodesChange={onNodesChange}
                onNodeDragStop={onNodeDragStop}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onInit={(reactFlowInstance: ReactFlowInstance) => {
                  const schema = new Schema({
                    process_name: processData.process_name,
                    process_description: processData.process_description,
                  });
                  schema.init(processData.process_schema);
                  setSchema(schema);
                  setReactFlowInstance(reactFlowInstance);
                }}
                edges={edges}
                nodeTypes={nodeTypes}
                onEdgesChange={handleEdgeChange}
                onEdgeUpdate={handleEdgeUpdate}
                onEdgeUpdateStart={handleEdgeUpdateStart}
                onEdgeUpdateEnd={handleEdgeUpdateEnd}
                onConnect={onConnect}
                deleteKeyCode={["Backspace", "Delete"]}
                onEdgesDelete={onEdgesDelete}
              >
                <Background
                  variant={"dots" as BackgroundVariant}
                  gap={20}
                  size={1}
                  color={"var(--grey-400)"}
                />
              </ReactFlow>
            </div>
            <div className={s.asideContainer}>
              <div className={s.aside}>
                <Collapse
                  className={s.asideCollapse}
                  defaultActiveKey={["1"]}
                  accordion
                  ghost
                  bordered
                  expandIcon={({ isActive }) => (
                    <AppArrowIcon
                      side={isActive ? "down" : "right"}
                      width={"16"}
                    />
                  )}
                >
                  <Collapse.Panel
                    className={s.asideCollapsePanel}
                    header={
                      <div className={s.asideTitle}>
                        {t("navigation.source")}
                      </div>
                    }
                    key="1"
                  >
                    <StoragesList
                      storages={storages}
                      handleDragStart={handleDragStart}
                    />
                  </Collapse.Panel>
                  <Collapse.Panel
                    className={s.asideCollapsePanel}
                    header={
                      <div className={s.asideTitle}>
                        {t("navigation.processes")}
                      </div>
                    }
                    key="2"
                  >
                    <ProcessesList />
                  </Collapse.Panel>
                  <Collapse.Panel
                    className={s.asideCollapsePanel}
                    header={
                      <div className={s.asideTitle}>
                        {t(
                          "page.process.form.step.lowcode.transformation.types"
                        )}
                      </div>
                    }
                    key="3"
                  >
                    <TransformationsList handleDragStart={handleDragStart} />
                  </Collapse.Panel>
                </Collapse>
              </div>
            </div>
            <GenerateSqlDrawer className={s.generateSql} tree={schema?.tree} />
          </ReactFlowProvider>
        </TransformContext.Provider>
        <div className={s.footer}>
          <div className={s.footerButtons}>
            <AppButton isOutline onClick={handleBack}>
              {t("page.process.form.step.action.back")}
            </AppButton>
            <AppButton onClick={() => setShowFinishModal(true)}>
              {t("steps.action.next")}
            </AppButton>
          </div>
        </div>
        <LowCodeViewProcessModal
          open={Boolean(processView)}
          shortProcess={processView}
          onCancel={() => showProcessView(null)}
          onConfirm={(fullProcess) => {
            const newNode = createProcessNode(processView, fullProcess);
            setNodes((nds) => nds.concat(newNode as any));
            showProcessView(null);
          }}
        />
        <LowCodeSelectEntityModal
          storage={entitySelectModal}
          open={Boolean(entitySelectModal)}
          onSubmit={submitSelectedEntities}
          onCancel={handleCloseEntitySelectModal}
        />
        <LowCodeEditEntityModal
          open={Boolean(entityEditModal)}
          edges={edges}
          storage={entityEditModal?.storage}
          node={entityEditModal?.node}
          onCancel={() => {
            showEntityEditModal(null);
          }}
          onConfirm={(
            fields: Array<{ id: string; name: string }>,
            deletedEdges: Edge[]
          ) => {
            const node = findNode(entityEditModal.node.id);
            node.data.fields = fields;

            onEdgesChange(
              deletedEdges.map(({ id }) => ({ id, type: "remove" }))
            );

            deletedEdges.forEach(({ target }) => {
              schema?.remove(target);
            });

            showEntityEditModal(null);
          }}
        />
        {lowCodeMergeModal()}
        <FinishModal
          open={showFinishModal}
          disabledActivate={!schema}
          onCancel={() => setShowFinishModal(false)}
          onActivate={() => handleSubmit(EProcessStatus.ACTIVE)}
          onDraft={() => handleSubmit(EProcessStatus.DRAFT)}
          onExit={onExit}
        />

        <AppConfirmModal
          open={Boolean(confirmEdgeUpdate)}
          title={t("page.process.form.step.lowcode.edges.remove.confirm.title")}
          isDanger={true}
          onConfirm={() => {
            if (!confirmEdgeUpdate) return;

            setEdges((els) =>
              updateEdge(
                confirmEdgeUpdate.edge,
                confirmEdgeUpdate.connection,
                els
              )
            );
            schema?.remove(confirmEdgeUpdate.edge.target);

            setConfirmEdgeUpdate(null);
          }}
          onCancel={() => {
            setConfirmEdgeUpdate(null);
          }}
        >
          {t("page.process.form.step.lowcode.edges.remove.confirm.body")}
        </AppConfirmModal>

        <AppConfirmModal
          open={Boolean(confirmRemoveEdges)}
          title={t("page.process.form.step.lowcode.edges.remove.confirm.title")}
          isDanger={true}
          onConfirm={() => {
            onEdgesChange(
              (confirmRemoveEdges as Edge[]).map(({ id }) => ({
                id,
                type: "remove",
              }))
            );

            confirmRemoveEdges?.forEach((e) => {
              schema?.remove(e.target);
            });

            setConfirmRemoveEdges(null);
          }}
          onCancel={() => {
            setConfirmRemoveEdges(null);
          }}
        >
          {t("page.process.form.step.lowcode.edges.remove.confirm.body")}
        </AppConfirmModal>

        <AppConfirmModal
          open={Boolean(confirmRemoveNode)}
          title={t("page.process.form.step.lowcode.nodes.remove.confirm.title")}
          isDanger={true}
          btnTextConfirm={t("page.process.card.actions.delete")}
          onConfirm={() => {
            const node = reactFlowInstance.getNode(confirmRemoveNode);

            if (node.type === "mergeTransformNode") {
              schema?.remove(node.id);
            }

            reactFlowInstance.deleteElements({ nodes: [node] });
            schema?.remove(findTransformKeyByNode(node));
            setEdges(
              edges
                .filter(
                  ({ source, target }) =>
                    source !== node.id && target !== node.id
                )
                .map((e) => ({ ...e, animated: false }))
            );
            setConfirmRemoveNode(null);
          }}
          onCancel={() => {
            setConfirmRemoveNode(null);
          }}
        >
          {removedNodeText}
        </AppConfirmModal>
      </div>
    </>
  );
};
