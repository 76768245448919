import React, { memo } from "react";
import { Tooltip } from "antd";
import cn from "classnames";

import {
  AppFullJoinIcon,
  AppInnerJoinIcon,
  AppLeftJoinIcon,
  AppRightJoinIcon,
} from "../../../../components/icons";
import { EMergeType } from "../../../../store/types/low-code.types";
import s from "./low-code-merge-modal.module.scss";

const JOIN_TYPES = {
  [EMergeType.LEFT]: {
    key: EMergeType.LEFT,
    name: "LEFT JOIN",
    icon: AppLeftJoinIcon,
  },
  [EMergeType.RIGHT]: {
    key: EMergeType.RIGHT,
    name: "RIGHT JOIN",
    icon: AppRightJoinIcon,
  },
  [EMergeType.FULL]: {
    key: EMergeType.FULL,
    name: "FULL JOIN",
    icon: AppFullJoinIcon,
  },
  [EMergeType.INNER]: {
    key: EMergeType.INNER,
    name: "INNER JOIN",
    icon: AppInnerJoinIcon,
  },
};

const MergeIcon = ({ mergeType }) => {
  const Icon = JOIN_TYPES[mergeType].icon;
  return (
    <Tooltip
      placement="bottom"
      title={JOIN_TYPES[mergeType].name}
      color={"#FFFFFF"}
    >
      <Icon />
    </Tooltip>
  );
};

type PropTypes = {
  mergeType: string;
  onChange: (status: string) => void;
};

export default memo(function ({ onChange, mergeType }: PropTypes) {
  return (
    <>
      <div className={s.mergeTypeWrapper}>
        {Object.values(JOIN_TYPES).map(({ key, name }) => (
          <div
            className={cn(s.mergeType, {
              [s.mergeTypeActive]: mergeType === name,
            })}
            key={key}
            data-testid={`${key}-join`}
            onClick={() => onChange(mergeType === name ? "" : name)}
          >
            <MergeIcon mergeType={key} />
            <span>{name}</span>
          </div>
        ))}
      </div>
    </>
  );
});
