import { getTimeZone } from "../../helpers/date.helper";

export enum EInterval {
  PER_HOUR = "perhour",
  PER_DAY = "perday",
  PER_WEEK = "perweek",
  PER_MONTH = "permonth",
}

export const intervalMapper = (interval: EInterval) => {
  switch (interval) {
    case EInterval.PER_HOUR:
      return "ежечасно";
    case EInterval.PER_DAY:
      return "ежедневно";
    case EInterval.PER_WEEK:
      return "еженедельно";
    case EInterval.PER_MONTH:
      return "ежемесячно";
  }
};

export const transformProcessDataToRequest = ({
  processData,
  timestamp,
  isLowcode,
}) => {
  if (isLowcode) {
    return {
      process_name: processData.process_name,
      process_description: processData.process_description,
      entities: processData.entities.map((id) => ({ id })),
      process_status: processData.process_status,
      process_sql: null,
      process_scheduler: null,
      process_is_overwriting: true,
      process_schema: {
        tree: processData.tree || null,
        rf: processData.rf || null,
      },
    };
  }

  const dataResolver = (time: string | null) => {
    return time === null ? null : Number(time);
  };

  const processSchedulerCondition =
    processData.onSchedule === "default"
      ? {
          process_scheduler: null,
        }
      : {
          process_scheduler: JSON.stringify({
            minute: dataResolver(timestamp.minute),
            hour: dataResolver(timestamp.hour),
            week: dataResolver(timestamp.week),
            month: dataResolver(timestamp.month),
            interval: timestamp.interval,
            timezone_offset: getTimeZone(),
          }),
        };

  return {
    process_name: processData.process_name,
    process_description: processData.process_description,
    entities: processData.entities,
    process_sql: processData.process_sql,
    process_status: processData.process_status,
    process_is_overwriting: processData.process_is_overwriting || false,
    ...processSchedulerCondition,
    process_schema: null,
  };
};
