import { memo, useEffect, useState } from "react";
import { Dropdown, Modal, Select, Space } from "antd";
import type { MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { AppButton } from "../../../../components/app-button/app-button.component";
import {
  AppDotMenuIcon,
  AppEditIcon,
  PlusIcon,
} from "../../../../components/icons";
import {
  IEntityTypeWithStorageData,
  AttributeType,
  MergeFormStepperItem,
} from "../../../../store/types/low-code.types";
import { useAppDispatch } from "../../../../store/hooks";
import { showMessage } from "../../../../store/snackbar/snackbar.slice";
import { ESnackbarState } from "../../../../store/types/snackbar.types";

import s from "./low-code-merge-modal.module.scss";

const { Option } = Select;

type PropsType = {
  entities: IEntityTypeWithStorageData[];
  countAvailableEntities: number;
  data: MergeFormStepperItem | null;
  attributeType?: string;
  onChange: (
    entity: IEntityTypeWithStorageData | null,
    attribute: AttributeType | null
  ) => void;
};

export default memo(function ({
  entities,
  countAvailableEntities,
  data,
  onChange,
  attributeType,
}: PropsType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entity, setEntity] = useState<IEntityTypeWithStorageData | null>(null);
  const [attribute, setAttribute] = useState<AttributeType | null>(null);
  const [isValidAttrType, setIsValidAttrType] = useState<boolean>(
    attributeType === attribute?.attribute_type
  );

  const items: MenuProps["items"] = [
    {
      key: "edit",
      label: (
        <div className={s.menuIcon}>
          <AppEditIcon />
          <div>{t("page.process.form.step.lowcode.merge.edit")}</div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      setEntity(data.entity);
      setAttribute(data.attribute);
    }
  }, [data]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    onChange(entity, attribute);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEntityChange = (value: string) => {
    const e = entities.find(({ id }) => String(id) === value);
    setEntity(e as IEntityTypeWithStorageData);
    setAttribute(null);
  };

  const onAttributeChange = (value: string) => {
    const attr = entity?.attributes.find(({ id }) => String(id) === value);
    setAttribute(attr as AttributeType);
    setIsValidAttrType(
      attributeType ? attributeType === attr?.attribute_type : true
    );
  };

  return (
    <div
      className={cn(s.rightJoin, {
        [s.rightJoinEmpty]: !data,
        [s.rightJoinFull]: !!data,
      })}
    >
      {data ? (
        <div className={s.fromSelect}>
          <div className={s.entityName} data-testid="right-entity-name">
            {data?.entity.entity_name}.
          </div>
          <Select
            data-testid="right-attribute"
            optionLabelProp="children"
            className={cn({
              "select-from-entity": true,
            })}
            placeholder={t("page.process.form.step.lowcode.merge.select_attr")}
            value={String(data?.attribute.id)}
            defaultValue={data?.attribute.id}
            onChange={(value) => {
              const attr = data?.entity.attributes.find(
                ({ id }) => String(id) === value
              ) as AttributeType;

              if (attributeType === attr.attribute_type) {
                onChange(data.entity, attr);
                setAttribute(attr ?? null);
              } else {
                dispatch(
                  showMessage({
                    message: "page.process.form.step.lowcode.merge.attr_error",
                    type: ESnackbarState.ERROR,
                  })
                );
              }
            }}
          >
            {data?.entity.attributes.map(
              ({ id, attribute_name, attribute_type }) => (
                <Option
                  key={id}
                  value={String(id)}
                  label={attribute_name}
                  className="select-from-entity-option"
                >
                  <div className="select-from-entity-option-item">
                    {attribute_name}
                  </div>
                  <div>
                    <span className="select-from-entity-option-label">
                      {t("page.process.form.step.lowcode.merge.type")}
                    </span>
                    <span className="select-from-entity-option-type">
                      {attribute_type}
                    </span>
                  </div>
                </Option>
              )
            )}
          </Select>
        </div>
      ) : (
        <AppButton isOutline onClick={showModal}>
          {t("page.process.form.step.lowcode.merge.add_entity")}
          &nbsp;
          <PlusIcon width="14" height="14" />
        </AppButton>
      )}
      <div
        className={cn(s.rightJoinPanel, {
          [s.rightJoinPanelFull]: !!data,
        })}
      >
        <div>
          {t("page.process.form.step.lowcode.merge.available")}
          <span className={s.entityAvailable}>{entities.length}</span>/
          {countAvailableEntities}
        </div>
        {data && (
          <Dropdown
            placement="bottomRight"
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "edit") {
                  setIsModalOpen(true);
                }
              },
            }}
          >
            <button type="button">
              <AppDotMenuIcon />
            </button>
          </Dropdown>
        )}
      </div>

      <Modal
        title={t("page.process.form.step.lowcode.merge.adding")}
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        wrapClassName="merge-form-add-entity"
        footer={false}
      >
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Select
            optionLabelProp="children"
            showArrow={true}
            showSearch={true}
            allowClear={true}
            placeholder={t(
              "page.process.form.step.lowcode.merge.select_left_entity_placeholder"
            )}
            onChange={handleEntityChange}
            value={entity ? String(entity.id) : null}
            defaultValue={String(data?.entity.id)}
            data-testid="select-right-entity"
          >
            {entities.map(({ id, entity_name, storage_name }) => (
              <Option value={String(id)} key={id}>
                <div className={"entity"} data-testid={`right-entity-${id}`}>
                  {entity_name}
                  <span className={"entity-storage-name"}>{storage_name}</span>
                </div>
              </Option>
            ))}
          </Select>
          <Select
            optionLabelProp="children"
            className="select-from-entity"
            placeholder={t("page.process.form.step.lowcode.merge.select_attr")}
            onChange={onAttributeChange}
            value={attribute ? String(attribute.id) : null}
            defaultValue={data?.attribute.id}
            data-testid="select-right-attribute"
          >
            {entity?.attributes.map(
              ({ id, attribute_name, attribute_type }) => (
                <Option
                  key={id}
                  value={String(id)}
                  label={attribute_name}
                  className="select-from-entity-option"
                >
                  <div data-testid={`right-attr-${id}`}>
                    <div className="select-from-entity-option-item">
                      {attribute_name}
                    </div>
                    <div>
                      <span className="select-from-entity-option-type">
                        {t("page.process.form.step.lowcode.merge.type")}
                      </span>
                      <span className="select-from-entity-option-label">
                        {attribute_type}
                      </span>
                    </div>
                  </div>
                </Option>
              )
            )}
          </Select>
          {attribute && !isValidAttrType && (
            <p className={s.dangerText}>
              {t("page.process.form.step.lowcode.merge.attr_error")}
            </p>
          )}
        </Space>
        <div className={s.addEntityOkButton}>
          <AppButton onClick={handleOk} disabled={!isValidAttrType}>
            {t("page.process.form.step.lowcode.merge.add_to_merge")}
          </AppButton>
        </div>
      </Modal>
    </div>
  );
});
