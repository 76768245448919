import { useState, useEffect, useCallback } from "react";
import { useSnackbar } from "notistack";
import { Typography, Dropdown } from "antd";
import { SyncOutlined, DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { selectStorage } from "../../../store/storages/storage.selector";
import { storagesSelectors } from "../../../store/storages/storages.selector";
import {
  fetchGSData,
  startCdcImport,
  stopCdcImport,
  updateModelAction,
  fetchStorageById,
} from "../../../api/storages/storages-acitons.api";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ESnackbarState } from "../../../store/types/snackbar.types";
import { ESourceCode, IStorageType } from "../../../store/types/storages.types";
import { AppCDCIcon } from "../../icons/app-CDC.icon";
import { AppDotsIcon } from "../../icons/app-dots.icon";
import { OnceImportIcon } from "../../icons/app-once-import.icon";
import { AppPlayIcon } from "../../icons/app-play.icon";
import { AppPauseIcon } from "../../icons/app-pause.icon";

import s from "./origin-header-actions.module.scss";

type PropTypes = {
  storageId: number;
  storageSourceType: ESourceCode;
  isSelectedEntity: boolean;
  disableStorageActions: boolean;
  onShowSettingStorage: () => void;
};

const { Text } = Typography;

export const OriginHeaderActions: React.FC<PropTypes> = ({
  storageId,
  storageSourceType,
  isSelectedEntity,
  onShowSettingStorage,
  disableStorageActions,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { currentStorage } = useAppSelector(selectStorage);
  const storageData = useAppSelector((state) =>
    storagesSelectors.selectById(state, storageId)
  ) as IStorageType;

  const checkSelectedEntity = (callback: () => void): void => {
    if (!isSelectedEntity) {
      enqueueSnackbar(t("source.detail.card.table.head.sync.choice"), {
        variant: ESnackbarState.ERROR,
      });
      return;
    }

    callback();
  };

  const startCDC = () => {
    if (!statusActions.permanentActiveBtn) return;
    checkSelectedEntity(() => {
      dispatch(startCdcImport(storageId));
    });
  };

  const stopCDC = () => {
    if (!statusActions.permanentActiveBtn) return;
    checkSelectedEntity(() => {
      dispatch(stopCdcImport(storageId));
    });
  };

  const startImportRawdata = () => {
    if (!statusActions.oneTimeStartBtn) return;
    checkSelectedEntity(() => {
      dispatch(fetchGSData(storageId));
    });
  };

  const updateEntity = () => {
    if (disableStorageActions) return;
    dispatch(updateModelAction(storageId)).then(() => {
      setTimeout(() => {
        dispatch(fetchStorageById(storageId));
      }, 2000);
    });
  };

  const StartLabel = ({ onStart }) => (
    <div className={s.label} onClick={onStart}>
      <AppPlayIcon width={"20"} height={"20"} />
      <span style={{ marginLeft: "4px" }}>
        {t("page.process.card.actions.start")}
      </span>
    </div>
  );

  const stopLabel = (
    <div className={s.label} onClick={stopCDC}>
      <AppPauseIcon width={"20"} height={"20"} />
      <span style={{ marginLeft: "4px" }}>
        {t("page.process.card.actions.stop")}
      </span>
    </div>
  );

  const defaultStatusActions = {
    oneTimeStartBtn: true,
    permanent: "start",
    permanentActiveBtn: true,
  };

  const prepareStatusActions = (data) => {
    const curStatusActions = defaultStatusActions;
    const { storage_import_status, storage_import_type } = data;

    if (storage_import_status === "starting") {
      curStatusActions.oneTimeStartBtn = false;
      curStatusActions.permanentActiveBtn = false;
    } else if (storage_import_status === "running") {
      curStatusActions.oneTimeStartBtn = false;

      if (storage_import_type === "continuous") {
        curStatusActions.permanent = "stop";
        return;
      }
      curStatusActions.permanentActiveBtn = false;
    } else if (
      storage_import_status === "stopping_inprogress" ||
      storage_import_status === "stopping_started"
    ) {
      curStatusActions.oneTimeStartBtn = false;

      if (storage_import_type === "continuous") {
        curStatusActions.permanent = "stop";
        curStatusActions.permanentActiveBtn = false;
        return;
      }
      curStatusActions.permanentActiveBtn = false;
    } else if (storage_import_status === "stopped") {
      if (storage_import_type === "continuous") {
        curStatusActions.oneTimeStartBtn = false;
        return;
      }
      curStatusActions.permanentActiveBtn = false;
    }
    return curStatusActions;
  };

  const [statusData, setStatusData] = useState({
    storage_import_status: storageData.storage_import_status,
    storage_import_type: storageData.storage_import_type,
  });

  const [statusActions, setStatusActions] = useState(
    prepareStatusActions(storageData) || defaultStatusActions
  );

  const { storage_import_type, storage_import_status } = statusData;

  useEffect(() => {
    const { id, storage_import_status, storage_import_type } = currentStorage;
    if (id === storageId) {
      setStatusData({ storage_import_status, storage_import_type });
    }
  }, [currentStorage]);

  useEffect(() => {
    const curStatusActions =
      prepareStatusActions(statusData) || defaultStatusActions;
    setStatusActions(curStatusActions);
  }, [statusData]);

  const prepareStatus = useCallback(
    (type: String) => {
      if (storage_import_type !== type) return <span>&mdash;</span>;

      if (
        storage_import_status === "starting" ||
        storage_import_status === "stopping_inprogress"
      ) {
        return <AppDotsIcon />;
      } else if (
        storage_import_status === "running" ||
        storage_import_status === "stopping_started"
      ) {
        return (
          <span style={{ color: "#00B21D" }}>
            {t("app.storage.status.running")}
          </span>
        );
      } else if (storage_import_status === "stopped") {
        return (
          <span style={{ color: "#107EFF" }}>
            {t("app.storage.status.stopped")}
          </span>
        );
      }
      return <span>&mdash;</span>;
    },
    [storage_import_status]
  );

  const oneTimeImportMenu = [
    {
      key: "1",
      label: <StartLabel onStart={startImportRawdata} />,
      disabled: !statusActions.oneTimeStartBtn,
    },
  ];

  const permanentImportMenu = [
    {
      key: "1",
      label:
        statusActions.permanent === "start" ? (
          <StartLabel onStart={startCDC} />
        ) : (
          stopLabel
        ),
      disabled: !statusActions.permanentActiveBtn,
    },
  ];

  return (
    <div className={s.textBlock}>
      {/* временное решение, согласно задаче DLH-1595 */}
      {/* <div
        id={"change-settings"}
        className={s.textBlock}
        onClick={onShowSettingStorage}
      >
        <AppGearIcon />
        <Text>{t("source.detail.card.head.change_settings")}</Text>
      </div> */}

      {storageSourceType !== "ds_xlsx" && (
        <>
          <div className={s.divider}></div>
          <div
            className={cn(s.textBlock, {
              [s.textBlockDisabled]: disableStorageActions,
            })}
            id={"update-entity"}
            onClick={updateEntity}
          >
            <SyncOutlined spin={false} />
            <span>{t("page.list.card.update_model")}</span>
          </div>
        </>
      )}

      <div className={s.divider}></div>

      <Dropdown
        menu={{ items: oneTimeImportMenu }}
        placement="bottomRight"
        arrow
        overlayStyle={{ minWidth: "100px" }}
        disabled={!isSelectedEntity}
      >
        <div
          className={cn(s.textBlock, {
            [s.textBlockDisabled]: !isSelectedEntity,
          })}
          id={"import-rawdata"}
        >
          <OnceImportIcon width={"28"} height={"28"} />
          <div>
            <Text
              className={cn({
                [s.textBlockDisabled]: !isSelectedEntity,
              })}
            >
              {t("source.detail.card.head.one_time_data_import")}
            </Text>
            <div className={s.statusBlock}>
              <span className={s.statusTitle}>
                {t("page.process.form.step.lowcode.process.view.status")}
              </span>
              {prepareStatus("one_time")}
            </div>
          </div>
          <DownOutlined style={{ fontSize: "18px", marginLeft: "8px" }} />
        </div>
      </Dropdown>

      {[ESourceCode.ORACLE, ESourceCode.PG, ESourceCode.MYSQL].includes(
        storageSourceType
      ) && (
        <>
          <div className={s.divider}></div>

          <Dropdown
            menu={{ items: permanentImportMenu }}
            placement="bottomRight"
            arrow
            overlayStyle={{ minWidth: "100px" }}
            disabled={!isSelectedEntity}
          >
            <div
              className={cn(s.textBlock, {
                [s.textBlockDisabled]: !isSelectedEntity,
              })}
              id={"start-cdc"}
            >
              <AppCDCIcon width={"20"} height={"20"} />
              <div>
                <Text
                  className={cn({
                    [s.textBlockDisabled]: !isSelectedEntity,
                  })}
                >
                  {t("source.detail.card.head.permanent_import")}
                </Text>
                <div className={s.statusBlock}>
                  <span className={s.statusTitle}>
                    {t("page.process.form.step.lowcode.process.view.status")}
                  </span>
                  {prepareStatus("continuous")}
                </div>
              </div>
              <DownOutlined style={{ fontSize: "18px", marginLeft: "8px" }} />
            </div>
          </Dropdown>
        </>
      )}
    </div>
  );
};
