/* eslint-disable sonarjs/cognitive-complexity */
export function deepSearch(
  data: any[],
  searchValue: string,
  ...path: Array<string>
): any[] {
  if (!searchValue) {
    return data;
  }

  const results: any[] = [];
  const [one, two, three] = path;

  data.forEach((storage: any) => {
    if (
      storage.storage_name.toLowerCase().includes(searchValue.toLowerCase())
    ) {
      results.push(storage);
    } else {
      storage?.[one].forEach((entity: any) => {
        if (
          entity.entity_name.toLowerCase().includes(searchValue.toLowerCase())
        ) {
          results.push({
            id: storage.id,
            storage_name_slug: storage.storage_name_slug,
            storage_name: storage.storage_name,
            [`${one}`]: [entity],
          });
        } else {
          entity?.[two].forEach((attribute: any) => {
            if (
              attribute.attribute_name
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
              results.push({
                storage_name: storage.storage_name,
                storage_name_slug: storage.storage_name_slug,
                id: storage.id,
                [`${one}`]: [
                  {
                    id: entity.id,
                    entity_name: entity.entity_name,
                    entity_name_slug: entity.entity_name_slug,
                    [`${two}`]: [attribute],
                  },
                ],
              });
            }
          });
        }
      });
    }
  });
  return results || [];
}
