import { Step } from "intro.js-react";

const PROCESS_NAV_ELEMENT = "#processes-nav";
const HELP_BUTTON_ELEMENT = "#help-button";

export const stepsHome: Step[] = [
  {
    element: "#some",
    intro: "steps.home.one",
  },
  {
    element: "#storage-nav",
    intro: "steps.home.two",
  },
  {
    element: "#home",
    intro: "steps.home.three",
  },
  {
    element: "#create-storage-button",
    intro: "steps.home.four",
  },
  {
    element: "#first-current",
    intro: "steps.home.five",
  },
  {
    element: "#refresh-current-storage-button",
    intro: "steps.home.six",
  },
  {
    element: "#data-quality",
    intro: "steps.home.seven",
  },
  {
    element: PROCESS_NAV_ELEMENT,
    intro: "steps.home.eight",
  },
  {
    element: "#some",
    intro: "steps.home.nine",
  },
  {
    element: HELP_BUTTON_ELEMENT,
    intro: "steps.home.ten",
  },
];

export const stepsOrigin: Step[] = [
  {
    element: "#some",
    intro: "steps.origin.one",
  },
  {
    element: "#first-current",
    intro: "steps.origin.two",
  },
  {
    element: "#entity-list",
    intro: "steps.origin.three",
  },
  {
    element: "#table-search",
    intro: "steps.origin.four",
  },
  {
    element: "#table-settings",
    intro: "steps.origin.five",
  },
  {
    element: "#save-button",
    intro: "steps.origin.six",
  },
  {
    element: "#actions-button",
    intro: "steps.origin.seven",
    position: "left",
  },
  {
    element: "#import-rawdata",
    intro: "steps.origin.eight",
  },
  {
    element: "#change-settings",
    intro: "steps.origin.nine",
  },
  {
    element: "#update-entity",
    intro: "steps.origin.ten",
  },
  {
    element: HELP_BUTTON_ELEMENT,
    intro: "steps.origin.eleven",
  },
];

export const stepsProcesses: Step[] = [
  {
    element: "#some",
    intro: "steps.processes.one",
  },
  {
    element: "#some",
    intro: "steps.processes.two",
  },
  {
    element: PROCESS_NAV_ELEMENT,
    intro: "steps.processes.three",
  },
  {
    element: PROCESS_NAV_ELEMENT,
    intro: "steps.processes.four",
  },
  {
    element: "#process-card-0",
    intro: "steps.processes.five",
  },
  {
    element: "#process-card-0-archive-btn",
    intro: "steps.processes.six",
  },
  {
    element: "#process-button-add",
    intro: "steps.processes.seven",
  },
  {
    element: HELP_BUTTON_ELEMENT,
    intro: "steps.processes.eight",
  },
];
