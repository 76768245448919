import React, { FC, useCallback, useContext, useState } from "react";
import s from "../../create-process.module.scss";
import cn from "classnames";
import { Tooltip, Tree } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { nanoid } from "@reduxjs/toolkit";
import { AppEntityIcon } from "../../../../components/icons/app-entity.icon";
import { AppSearchIcon } from "../../../../components/icons/app-search.icon";
import { AppInput } from "../../../../components/app-input/app-input.component";
import { IntroContext } from "../../../../providers/intro-provider/intro.provider";
import { Control } from "react-hook-form";
import { deepSearch } from "../../../../helpers/search.helper";
import { debounce } from "../../../../utils/utils";

interface DataTreePropTypes {
  storageList: any;
  handleWriteSqlData: (data: string) => void;
  control: Control<any>;
}

export const DataTree: FC<DataTreePropTypes> = ({
  storageList,
  handleWriteSqlData,
  control,
}) => {
  const { autoComplete } = useContext(IntroContext);
  const [search, setSearch] = useState<any>(storageList);
  const [openBranch, setOpenBranch] = useState(false);
  const { t } = useTranslation();

  const onChangeSearch = (value: string) => {
    setOpenBranch(!!value);
    setSearch(deepSearch(storageList, value, "selectedEntities", "attributes"));
  };
  const debounceFn = useCallback(debounce(onChangeSearch, 1000), []);

  return (
    <>
      <AppInput
        icon={<AppSearchIcon />}
        sharedStyles={s.searchInput}
        autoComplete={autoComplete}
        onChange={(value) => debounceFn(value.currentTarget.value)}
        name={"search"}
        control={control}
        placeholder={
          t("page.process.form.step.three.search.placeholder") as string
        }
      />
      <div className={s.currentSearch}>
        <div className={cn(s.scrollContainer, s.scrollContainerStepThree)}>
          <Tree
            autoExpandParent={openBranch}
            showLine
            blockNode
            icon={false}
            selectedKeys={[]}
            activeKey=""
          >
            {search?.map((storage, storage_idx) => {
              return (
                <Tree.TreeNode
                  key={nanoid()}
                  title={
                    <div className={s.attributeContainer}>
                      <div className={s.collapseHeaderText}>
                        {storage.storage_name}
                        <span className={s.slugText}>
                          <span>slug: </span>
                          {storage.storage_name_slug}
                        </span>
                      </div>
                    </div>
                  }
                >
                  {storage?.selectedEntities.map((entity, entity_idx) => {
                    return (
                      <Tree.TreeNode
                        key={nanoid()}
                        title={
                          <Tooltip
                            placement={"right"}
                            title={
                              <div className={s.tooltipSlug}>
                                <span className={s.tooltipSlugLabel}>
                                  {t("page.process.form.step.three.tree.theme")}
                                  :
                                </span>
                                <span className={s.tooltipSlugData}>
                                  {entity.entity_schema}
                                </span>
                                <span className={s.tooltipSlugLabel}>
                                  {t(
                                    "page.process.form.step.three.tree.slug_theme"
                                  )}
                                  :
                                </span>
                                <span className={s.tooltipSlugData}>
                                  {entity.entity_schema_slug}
                                </span>
                              </div>
                            }
                            color={"var(--white)"}
                          >
                            <div
                              className={cn(
                                s.collapseHeader,
                                s.attributeContainer
                              )}
                            >
                              <div className={s.collapseHeaderWrapper}>
                                <AppEntityIcon />

                                <div className={s.collapseHeaderText}>
                                  {entity.entity_name}
                                  <span className={s.slugText}>
                                    <span>slug: </span>
                                    {entity.entity_name_slug}
                                  </span>
                                </div>
                              </div>

                              <SqlBtn
                                onCLick={handleWriteSqlData}
                                data={`${storage.storage_name_slug}.${entity.entity_schema_slug}_${entity.entity_name_slug}`}
                              />
                            </div>
                          </Tooltip>
                        }
                      >
                        {entity?.attributes.map((attribute, idx) => {
                          return (
                            <Tree.TreeNode
                              key={nanoid()}
                              title={
                                <div className={s.attributeContainer}>
                                  <div className={s.attributeContainerContent}>
                                    {attribute.attribute_name}
                                    <div className={s.attributeParams}>
                                      <span className={s.slugText}>
                                        <span>slug: </span>
                                        {attribute.attribute_name_slug}
                                      </span>
                                      <div
                                        style={{
                                          fontSize: 8,
                                          lineHeight: "8px",
                                          color:
                                            "rgba(var(--accent-second-rgb), 0.5)",
                                        }}
                                      >
                                        |
                                      </div>
                                      <span className={s.slugText}>
                                        <span>
                                          {t(
                                            "page.process.form.step.three.tree.type"
                                          )}
                                          :
                                        </span>
                                        {attribute.attribute_type}
                                      </span>
                                    </div>
                                  </div>
                                  <SqlBtn
                                    onCLick={handleWriteSqlData}
                                    data={attribute.attribute_name_slug}
                                    tooltipLabel={
                                      t(
                                        "page.process.form.step.three.tree.theme"
                                      ) as string
                                    }
                                    tooltipDesc={attribute.attribute_scheme}
                                  />
                                </div>
                              }
                            ></Tree.TreeNode>
                          );
                        })}
                      </Tree.TreeNode>
                    );
                  })}
                </Tree.TreeNode>
              );
            })}
          </Tree>
        </div>
      </div>
    </>
  );
};

interface ISqlButtonPropsType {
  onCLick: (data: string) => void;
  data: string;
  tooltipLabel?: string;
  tooltipDesc?: string;
}

const SqlBtn: React.FC<ISqlButtonPropsType> = ({ onCLick, data }) => {
  return (
    <div
      className={s.sqlBtn}
      onClick={(e) => {
        e.stopPropagation();
        onCLick(data);
      }}
    >
      SQL <PlusOutlined className={s.icon} />
    </div>
  );
};
