import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  getConnectedEdges,
} from "reactflow";
import type { Node, ReactFlowInstance } from "reactflow";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppButton } from "../../../components/app-button/app-button.component";
import {
  INodeTreeTypes,
  IStorageType,
} from "../../../store/types/storages.types";
import {
  StorageNode,
  MergeTransformNode,
  ProcessNode,
} from "../../../components/custom-model-node";
import { AppLogoLoader } from "../../../components/ui/app-animated-logo/app-animated-logo.component";
import { selectProcessDetail } from "../../../store/processes/process-detail/process-detail.selector";
import { useAppSelector } from "../../../store/hooks";

import s from "../../create-process/steps/low-code.module.scss";
import { Schema } from "../../create-process/schema";
import { TransformContext } from "../../create-process/steps/transform.provider";
import LowCodeMergeModal from "../../create-process/steps/low-code-merge-modal/low-code-merge-modal.component";
import { MergeDataType } from "../../../store/types/low-code.types";

type PropTypes = {
  storages: IStorageType[];
  onBack: () => void;
};

const nodeTypes = {
  storageNode: StorageNode,
  processNode: ProcessNode,
  mergeTransformNode: MergeTransformNode,
};

export const StepViewLowCodePlace: React.FC<PropTypes> = ({
  storages,
  onBack,
}) => {
  const { t } = useTranslation();
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const { currentProcess } = useAppSelector(selectProcessDetail);
  const [nodeSelected, setNodeSelected] = useState<any>(null);
  const [nodes, setNodes] = useNodesState<Array<INodeTreeTypes>>(
    currentProcess.process_schema?.rf?.nodes ?? []
  );
  const [edges] = useEdgesState(currentProcess.process_schema?.rf?.edges ?? []);
  const [schema, setSchema] = useState<Schema>();
  const [mergeNode, setMergeNode] = useState<Node>();
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [confirmRemoveNode, setConfirmRemoveNode] = useState<string | null>(
    null
  );
  const [entityEditModal, showEntityEditModal] = useState<any>(null);

  const transformation = useMemo(
    () =>
      mergeNode?.data.index
        ? schema?.find(`merge_${mergeNode?.data.index}`)?.toObject()
        : null,
    [mergeNode]
  );

  const cleanSelectedNodes = () => {
    const cleanNodes = nodes.map((nodeElem) => {
      return {
        ...nodeElem,
        selected: false,
      };
    });
    setNodes(cleanNodes);
  };
  useEffect(() => {
    if (nodes.some((node) => node.selected) && nodeSelected === null) {
      cleanSelectedNodes();
    }
  }, [nodeSelected]);

  const handleFlowWrapperClick = useCallback(() => {
    setNodeSelected(null);
  }, []);

  const handleBack = () => {
    onBack();
  };

  if (!storages) {
    return (
      <div className={s.container}>
        <AppLogoLoader loading={true} />
      </div>
    );
  }

  const snapGrid: [number, number] = [16, 16];
  const findNode = (nodeId: string): Node => reactFlowInstance.getNode(nodeId);

  const parentTransformKey = (mergeData: MergeDataType) => {
    const parentIncomer = mergeData.incomers.find(({ storage }) =>
      String(storage.id).includes("merge")
    );
    return parentIncomer ? parentIncomer.storage.id : null;
  };

  const handleMergeSubmit = (mergeData: MergeDataType) => {
    schema?.add(mergeData, parentTransformKey(mergeData));
    // onUpdate({ tree: schema?.toObject(), });
  };

  return (
    <>
      <div className={s.container}>
        <TransformContext.Provider
          value={{
            onChangeSettings: (nodeId: string) => {
              setMergeNode(findNode(nodeId));
            },
            onEmitDeleteNode: (nodeId: string) => {
              const node = findNode(nodeId);
              const connectedEdges = getConnectedEdges([node], edges);

              if (
                node.type === "mergeTransformNode" &&
                !currentProcess.process_schema
              ) {
                reactFlowInstance.deleteElements({ nodes: [node] });
                return;
              }

              if (connectedEdges.length) {
                setConfirmRemoveNode(nodeId);
                return;
              }

              reactFlowInstance.deleteElements({ nodes: [node] });
            },
            onEmitEditNode: (nodeId: string | null) => {
              showEntityEditModal({
                storage: storages.find(
                  ({ id }) => Number(id) === Number(nodeId)
                ),
                node: nodes.find(({ id }) => Number(id) === Number(nodeId)),
              });
            },
          }}
        >
          <ReactFlowProvider>
            <div
              className={s.wrapper}
              ref={reactFlowWrapper}
              onClick={() => handleFlowWrapperClick()}
            >
              <ReactFlow
                id={"process-low-code-area"}
                fitViewOptions={{ maxZoom: 700, padding: 1 }}
                nodes={nodes}
                snapToGrid
                snapGrid={snapGrid}
                edges={edges}
                nodeTypes={nodeTypes}
                onInit={(reactFlowInstance: ReactFlowInstance) => {
                  const schema = new Schema({
                    process_name: currentProcess.process_name,
                    process_description: currentProcess.process_description,
                  });
                  schema.init(currentProcess.process_schema);
                  setSchema(schema);
                  setReactFlowInstance(reactFlowInstance);
                }}
              >
                <Background
                  variant={"dots" as BackgroundVariant}
                  gap={20}
                  size={1}
                  color={"var(--grey-400)"}
                />
              </ReactFlow>
            </div>
          </ReactFlowProvider>
        </TransformContext.Provider>
        <div className={s.footer}>
          <div className={s.footerButtons}>
            <AppButton isOutline onClick={handleBack}>
              {t("page.process.form.step.action.back")}
            </AppButton>
            <NavLink to={"/processes"}>
              <AppButton>{t("page.process.prompt.exit.confirm")}</AppButton>
            </NavLink>
          </div>
        </div>
        <LowCodeMergeModal
          pageMode={"view"}
          rf={reactFlowInstance}
          schema={schema}
          transformNode={mergeNode}
          transformation={transformation}
          onSubmit={handleMergeSubmit}
          onCancel={() => setMergeNode(undefined)}
        />
      </div>
    </>
  );
};
