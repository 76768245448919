import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Steps, Tag } from "antd";
import ReactRouterPrompt from "react-router-prompt";

import { fetchStorageForProcess } from "../../api/storages/storages-acitons.api";
import { processGenerateSql } from "../../api/processes/processes-actions.api";
import {
  editProcess,
  fetchProcessById,
} from "../../api/processes/processes-actions.api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setCurrentProcess,
  setTouched,
} from "../../store/processes/process-detail/process-detail.slice";
import { selectProcessDetail } from "../../store/processes/process-detail/process-detail.selector";

import { AppConfirmModal } from "../../components/ui/app-confirm-modal/app-confirm-modal.component";
import { AppContent } from "../../components/app-main.layout";
import { StepEdit1 } from "./steps/step-edit1.component";
import { StepEdit2 } from "./steps/step-edit2.component";
import { StepEdit3 } from "./steps/step-edit3.component";
import { StepEditLowCodePlace } from "./steps/step-edit-low-code-place.component";
import { AppArrowIcon } from "../../components/icons/app-arrow.icon";
import { OnlineAccess } from "../../components/app-online/app-online.component";
import { transformProcessDataToRequest } from "../create-process/process.helper";
import {
  IDomainProcess,
  EProcessStatus,
} from "../../store/types/processes.types";

import s from "./process-edit.module.scss";

const PROCESSES_ROUTE = "/processes";

export const ProcessEditPage: React.FC<{}> = () => {
  const [storages, setStorages] = useState<any>(null);
  const [current, setCurrent] = useState<number>(0);

  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentProcess, touched } = useAppSelector(selectProcessDetail);
  const { process_schema, process_status_flow } = currentProcess;
  const [processData, setProcessData] =
    useState<IDomainProcess>(currentProcess);
  const [timestamp, setTimestamp] = useState(currentProcess.process_scheduler);

  useEffect(() => {
    setProcessData(currentProcess);
  }, [currentProcess]);

  useEffect(() => {
    dispatch(fetchProcessById(Number(id))).then((data) => {
      if (!data.payload?.data) {
        return Promise.reject(data);
      }
      dispatch(setCurrentProcess(data.payload.data));
    });
  }, []);

  useEffect(() => {
    return () => {
      setTouch(false);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchStorageForProcess())
      .unwrap()
      .then((data) => {
        const filtered = data.data.filter(
          (storage: { entities: string | any[] }) => storage.entities.length > 0
        );
        setStorages(filtered);
      });
  }, []);

  const handleExit = () => {
    setTouch(false);
    navigate(PROCESSES_ROUTE);
  };

  const next = useCallback(() => {
    setCurrent(current + 1);
  }, [current]);

  const back = useCallback(() => {
    setCurrent(current - 1);
  }, [current]);

  const setTouch = (condition: boolean) => {
    dispatch(setTouched(condition));
  };

  function onSubmit(data) {
    const _processData = { ...processData, ...data };
    setProcessData(_processData);
    const payload = transformProcessDataToRequest({
      processData: _processData,
      timestamp,
      isLowcode: Boolean(data.rf),
    });

    if (process_schema && data.process_status === EProcessStatus.ACTIVE) {
      dispatch(processGenerateSql(data.tree));
    }

    dispatch(editProcess({ id, ...payload }))
      .unwrap()
      .then((data) => {
        if (data.code === "process") {
          setTouch(false);
          setTimeout(() => {
            navigate(PROCESSES_ROUTE);
          }, 1000);
        }
      });
  }

  function changedData(fields: string[]) {
    const condition = fields.some((field) => field.length);
    setTouch(condition);
  }

  function onStepData(data: any) {
    setProcessData({
      ...processData,
      ...data,
    });
    next();
  }

  const steps = process_schema
    ? [
        {
          title: t("page.process.form.step.basic_details"),
          className: s.processBody,
          content: (
            <StepEdit1
              onChangeStepData={changedData}
              processData={processData}
              onUpdate={onStepData}
              isLowcode={Boolean(currentProcess.process_schema)}
              setTimestamp={setTimestamp}
            />
          ),
        },
        {
          title: t("page.process.form.step.enter_request"),
          className: s.processLowCodeBody,
          content: (
            <StepEditLowCodePlace
              processData={processData}
              storages={storages as any}
              onBack={() => back()}
              onSubmit={onSubmit}
              onExit={handleExit}
            />
          ),
        },
      ]
    : [
        {
          title: t("page.process.form.step.basic_details"),
          className: s.processBody,
          content: (
            <StepEdit1
              onChangeStepData={changedData}
              processData={processData}
              onUpdate={onStepData}
              setTimestamp={setTimestamp}
            />
          ),
        },
        {
          title: t("page.process.form.step.source_chose"),
          className: s.processBody,
          content: (
            <StepEdit2
              processData={processData}
              storages={storages}
              onBack={() => back()}
              onUpdate={onStepData}
            />
          ),
        },
        {
          title: t("page.process.form.step.enter_request"),
          className: s.processBody,
          content: (
            <StepEdit3
              processData={processData}
              storages={storages}
              sql={processData.process_sql}
              onBack={() => back()}
              onSubmit={onSubmit}
            />
          ),
        },
      ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const blockerFunction: any = () => {
    return touched;
  };

  const handleOnConfirm = (onConfirm: () => void) => {
    onConfirm();
  };
  const handleOnCancel = (onCancel: () => void) => {
    onCancel();
  };

  return (
    <>
      <AppContent isFull>
        <OnlineAccess>
          <div className={s.processHead}>
            <div className={s.leftBlock} id={"first-current"}>
              <NavLink to={PROCESSES_ROUTE} className={s.processBlock}>
                <AppArrowIcon side={"left"} />
                <span>{t("page.process-edit.header")}</span>
              </NavLink>
              {process_status_flow && (
                <Tag color="#0057ff33">{process_status_flow}</Tag>
              )}
            </div>
            <div className={s.rigntBlock}>
              <Steps
                className={s.stepsContainer}
                current={current}
                items={items}
              />
            </div>
          </div>
          <div className={steps[current].className || ""}>
            {steps[current].content}
          </div>
          <ReactRouterPrompt when={blockerFunction}>
            {({ isActive, onConfirm, onCancel }) => (
              <AppConfirmModal
                open={isActive}
                cancelText={"page.process.card.archive.form.decline.form"}
                title={"page.process.prompt.exit.title"}
                isDanger={true}
                btnTextConfirm={"page.process.prompt.exit.confirm" || ""}
                onConfirm={() => handleOnConfirm(onConfirm)}
                onCancel={() => handleOnCancel(onCancel)}
              >
                <p>{t("page.process.prompt.exit.description")}</p>
              </AppConfirmModal>
            )}
          </ReactRouterPrompt>
        </OnlineAccess>
      </AppContent>
    </>
  );
};
