import React, { useEffect, useRef } from "react";
import cn from "classnames";
import s from "./app-origin-tab.module.scss";
import { AppArrowIcon } from "../../../icons/app-arrow.icon";
import { AppConnectionIcon } from "../../../icons/app-connection.icon";

interface AppOriginTabProps {
  onSetActive: (id: number) => void;
  tabId: number;
  selectedId: number;
  name: string;
  isSync: boolean;
  isUnsaved: boolean;
  toScroll: (offsetY?: number) => void;
}

export const AppOriginTab: React.FC<AppOriginTabProps> = ({
  tabId,
  onSetActive,
  name,
  selectedId,
  isSync,
  isUnsaved,
  toScroll,
}) => {
  const tabRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (selectedId === tabId) {
      const offsetY = tabRef.current?.getBoundingClientRect().y;
      toScroll(offsetY);
    }
  }, [tabRef]);
  return (
    <div
      ref={tabRef}
      className={cn(s.originTab, {
        [s._active]: selectedId === tabId,
        [s._unsaved]: isUnsaved,
      })}
      onClick={() => onSetActive(+tabId)}
    >
      <h3>{name}</h3>

      <div className={s.tabIcons}>
        {isSync ? <AppConnectionIcon className={s.iconSync} /> : null}
        <AppArrowIcon className={s.icon} side={"right"} />
      </div>
    </div>
  );
};
