import React, { useState, useEffect } from "react";
import { ColumnsType } from "antd/lib/table";
import { Checkbox, Table, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";

import { useForm } from "react-hook-form";
import { AttachRuleToAttributeModal } from "./attach-rule-to-attribute-modal/attach-rule-to-attribute-modal.component";
import { selectAttributeRules } from "../../../store/storages/attribute-rules.selector";
import { OriginTableSearch } from "./origin-table-search/origin-table-search.component";
import { UploadFileModal } from "./upload-csv-file-modal/upload-file-modal.component";
import { AppEditAttributeRule } from "./app-edit-attribute-rule/app-edit-attribute-rule.component";
import { selectStorage } from "../../../store/storages/storage.selector";
import { IEntriesAttributeTypes } from "../../../store/types/storages.types";
import { useAppSelector } from "../../../store/hooks";

import s from "./app-origin-table.module.scss";

interface IAppOriginTableProps {
  entity: any;
  entityType: string;
  storageId: number;
  attributes: IEntriesAttributeTypes[];
  isSelectedEntity: boolean;
  disableStorageActions: boolean;
  onEntitySelected: (
    entityIsSelected: boolean,
    attributes: IEntriesAttributeTypes[]
  ) => void;
  onAttributeSelected: (
    id: number,
    changes: Partial<IEntriesAttributeTypes>
  ) => void;
}

export const AppOriginTable: React.FC<IAppOriginTableProps> = ({
  entity,
  entityType,
  storageId,
  attributes,
  isSelectedEntity,
  disableStorageActions,
  onEntitySelected = () => null,
  onAttributeSelected = () => null,
}) => {
  const { control, watch, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      search: "",
    },
  });
  const search = watch("search");
  const { t } = useTranslation();

  const dataRules = useAppSelector(selectAttributeRules);

  const [showUploadCsvModal, setShowUploadCsvModal] = useState(false);
  const [edit, showEdit] = useState(false);
  const [showAttributeRuleModal, setShowAttributeRuleModal] = useState(false);
  const [selectedAttribute, setSelectedAttribute] =
    useState<IEntriesAttributeTypes | null>(null);

  const attributeList: IEntriesAttributeTypes[] = search
    ? attributes.filter((item: IEntriesAttributeTypes) => {
        return item.attribute_name.toLowerCase().includes(search.toLowerCase());
      })
    : attributes;

  const canEdit = (edit: boolean) => {
    showEdit(edit);
  };

  function handleShowUploadCsvModal(): void {
    setShowUploadCsvModal(true);
  }

  const handleCancelUploadCsvModal = () => {
    setSelectedAttribute(null);
    setShowUploadCsvModal(false);
  };

  const handleEntitySelectedChange = (event: CheckboxChangeEvent) => {
    reset();
    onEntitySelected(event.target.checked, attributeList);
  };

  const getAttributeRuleNames = (ruleId: string) => {
    const findedRule = dataRules.find(
      (rule) => String(rule.id) === String(ruleId)
    );
    return findedRule ? findedRule.rule_template_name : "";
  };
  const setAttributeRule = (attr: IEntriesAttributeTypes) => {
    setSelectedAttribute(attr);
    setShowAttributeRuleModal(true);
  };
  const handleCancelAttributeRule = () => {
    setSelectedAttribute(null);
    setShowAttributeRuleModal(false);
  };

  const columns: ColumnsType<any> = [
    {
      width: "30%",
      title: (
        <div className={s.rowWrapper}>
          <Checkbox
            disabled={disableStorageActions}
            checked={isSelectedEntity}
            onChange={handleEntitySelectedChange}
          >
            <p className={s.tableTitle}>
              {t("source.detail.card.table.head.sync")}
            </p>
          </Checkbox>
        </div>
      ),
      key: "action",
      render: (_, record) => {
        const handleChange = (event: CheckboxChangeEvent) => {
          onAttributeSelected(record.id, {
            attribute_is_selected: event.target.checked,
          });
        };

        return (
          <div className={s.rowWrapper}>
            <div className={s.originSync}>
              <Checkbox
                disabled={disableStorageActions}
                checked={record.attribute_is_selected}
                onChange={handleChange}
              />
              <div className={s.originAttr}>
                <span>{t("source.detail.card.table.head.attribute")}:</span>
                <p>{record.attribute_name}</p>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>
            {t("source.detail.card.table.head.type")}
          </p>
        </div>
      ),
      dataIndex: "type",
      key: "type",
      render: (_, { attribute_type }) => (
        <div className={s.rowWrapper}>
          <p className={s.tableContent}>{attribute_type}</p>
        </div>
      ),
    },
    {
      title: (
        <div className={s.rowWrapper}>
          <Tooltip title="null/not null" color={"var(--white)"}>
            <p className={s.tableTitle}>
              {t("source.detail.card.table.head.n")}
            </p>
          </Tooltip>
        </div>
      ),
      dataIndex: "type",
      key: "type",
      render: (_, { attribute_is_nullable }) => (
        <div className={s.rowWrapper}>
          <p className={s.tableContent}>
            {t(
              attribute_is_nullable
                ? "source.detail.card.table.head.n.yes"
                : "source.detail.card.table.head.n.no"
            )}
          </p>
        </div>
      ),
    },
  ];

  let fileFormat;
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (entityType) {
    case "ds_xlsx":
      fileFormat = "xlsx";
      break;
    default:
      fileFormat = "csv";
  }

  return (
    <div className={s.originTable}>
      <OriginTableSearch
        storageId={storageId}
        entityId={entity.id}
        isSelectedEntity={isSelectedEntity}
        searchControl={control}
        onShowUploadCsvModal={handleShowUploadCsvModal}
        fileFormat={fileFormat}
      />

      {attributeList.length ? (
        <div className={s.originListWrapper}>
          <div className={s.originList} id={"table-settings"}>
            <Table
              bordered
              size="small"
              rowKey={(fieldkey) => fieldkey.id}
              columns={columns}
              pagination={false}
              dataSource={attributeList}
            />
          </div>
        </div>
      ) : (
        <div className={s.emptyTable}>
          {t("source.detail.card.flow.no_attributes")}
        </div>
      )}

      <UploadFileModal
        entity={entity}
        storageId={Number(storageId)}
        open={showUploadCsvModal}
        onCancel={handleCancelUploadCsvModal}
        fileFormat={fileFormat}
      />

      <AttachRuleToAttributeModal
        attribute={selectedAttribute}
        open={showAttributeRuleModal}
        onCancel={handleCancelAttributeRule}
      />
    </div>
  );
};
