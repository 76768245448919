import type { Node } from "reactflow";
import { EProcessStatus } from "./processes.types";
import { XYPosition } from "reactflow";

export enum EMergeType {
  LEFT = "left",
  RIGHT = "right",
  INNER = "inner",
  FULL = "full",
}

export type StorageType = {
  id: string;
  storage_name: string;
  storage_name_slug: string;
  entities: IEntityType[];
};

export interface IEntityType {
  id: string | number;
  entity_name: string;
  entity_name_slug: string;
  entity_schema: string;
  entity_schema_slug: string;
  attributes: AttributeType[];
}

export interface IEntityTypeWithStorageData extends IEntityType {
  storage_name?: string;
}

export type MergeIncomerType = {
  storage: StorageType;
  entity: IEntityType;
  node: Node;
  type: "storage" | "process" | "merge";
};

export type MergeDataType = {
  name: string;
  joins: Join[];
  incomers: MergeIncomerType[];
  node: Node;
};

export type AttributeType = {
  attribute_schema?: string;
  attribute_type: string;
  attribute_name: string;
  attribute_name_slug: string;
  id: string;
  entity_id?: string | number;
};

export type ScheduleType = {
  minute: string;
  hour: string;
  week: string;
  month: string;
  interval: string;
  timezone_offset: number;
};

export type ShortProcessType = {
  id: number;
  name: string;
  status: EProcessStatus;
  position: XYPosition;
};

export type ProcessType = {
  loading: boolean;
  error: string;
  date: string;
  entity: any;
  schedule: ScheduleType | null;
};

export type JoinDetails = {
  entityId: string | number;
  attributeId: string | number;
};

export type JoinItem = {
  id: string;
  type: "storage" | "process" | "merge";
  storageName: string;
  storageNameSlug: string;
  entityId: string;
  entityName: string;
  entityNameSlug: string;
  entitySchema: string;
  entitySchemaSlug: string;
  attributeId: string;
  attributeName: string;
  attributeNameSlug: string;
  attributeType: string;
};

export type Join = {
  index: number;
  isValid?: boolean;
  left?: JoinItem;
  right?: JoinItem;
  variant?: string;
};

export type MergeFormStepperItem = {
  entity: IEntityType;
  attribute: AttributeType;
};
