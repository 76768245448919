import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, RegisterOptions, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Select, Upload, Checkbox, Tooltip } from "antd";
import type { UploadProps } from "antd";
import * as XLSX from "xlsx";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  createConnections,
  editConnectionsById,
  fetchConnections,
} from "../../../api/connections/connections-actions.api";
import { selectAllConnections } from "../../../store/connections/connnections.selector";
import {
  ICreateConnectionTypes,
  IStorageConfigTypes,
} from "../../../store/types/connections.types";
import { IStorageType, ESourceCode } from "../../../store/types/storages.types";
import { IntroContext } from "../../../providers/intro-provider/intro.provider";
import { AppInput } from "../../app-input/app-input.component";
import { AppButton } from "../../app-button/app-button.component";
import { AppCheckbox } from "../../../components/app-checkbox/app-checkbox.component";
import { AppXlsxIcon } from "../../icons/app-xlsx.icon";
import { AppQuestionIcon } from "../../icons/app-question.icon";

import s from "./app-create-current.module.scss";

const { Dragger } = Upload;

type AppCreateCurrentFormPropTypes = {
  onCancel: () => void;
  origin?: null | IStorageType;
};

const addFileText =
  "source.detail.card.actions.actions_button.upload_xlsx.start";

export const AppCreateCurrent: React.FC<AppCreateCurrentFormPropTypes> = ({
  onCancel,
  origin = null,
}) => {
  const dispatch = useAppDispatch();
  const { autoComplete } = useContext(IntroContext);
  const { connectionTypes, connections } = useAppSelector(selectAllConnections);
  const { t } = useTranslation();
  const [file, setFile] = useState(addFileText);
  const [showCheckboxHeaders, setShowCheckboxHeaders] = useState(false);
  const [isHeaders, setIsHeaders] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const methods = useForm({
    mode: "onChange",
    defaultValues:
      origin !== null
        ? {
            source_type: origin.storage_datasource_code || ESourceCode.NONE,
            name: origin.storage_name,
            host: origin.storage_conn_config.host,
            db: origin.storage_conn_config.db,
            port: origin.storage_conn_config.port,
            user: origin.storage_conn_config.user,
            password: origin.storage_conn_config.password,
            link: origin.storage_conn_config.link,
            service_name: origin.storage_conn_config.service_name,
            headers: false,
            fileXlsx: "",
            from: null,
            to: null,
          }
        : {},
  });

  const { Option } = Select;
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    unregister,
    clearErrors,
    formState: { errors, isValid },
  } = methods;
  const watchSourceType = watch("source_type");
  const watchFrom = watch("from");
  const fileField = watch("fileXlsx") as any;

  const validateSameName = (value: string): string | boolean => {
    const connectionNames = connections
      .map((connect) => connect.storage_name.toLowerCase())
      .filter((name) => name !== origin?.storage_name);
    return (
      !connectionNames.includes(value.toLowerCase()) ||
      (t("modal.source.create.validation.same-name") as string)
    );
  };
  const validateSymbols = (value: string): string | boolean => {
    const regex = new RegExp(/[^a-zA-ZЁёА-я0-9\s-]/g);
    return (
      !value.match(regex) ||
      (t("page.process.form.step.validation.spec_symbols") as string)
    );
  };

  const validatePG = (value: string): string | boolean => {
    return (
      !value.toLowerCase().startsWith("pg") ||
      (t("modal.source.create.validation.pg-name") as string)
    );
  };

  const validateRowsMoreZero = (value) => {
    return (
      Number(value) > 0 || (t("modal.source.create.validate_error_1") as string)
    );
  };

  const validateFileFormat = (value) => {
    const allowedFormat = "xlsx"; //TODO: make a universal variable like currentFileformat if there are other file formats
    const uploadFileFormat = value.file.name.split(".").pop().toLowerCase();
    return allowedFormat === uploadFileFormat || "Недопустимый формат файла";
  };

  const validateRowsLessCountOfRows = (value) => {
    return (
      Number(value) <= rowCount ||
      (t("modal.source.create.validate_error_2") as string)
    );
  };

  const validateRowsFromLessTo = (value) => {
    if (watchFrom) {
      return (
        Number(watchFrom) <= Number(value) ||
        (t("modal.source.create.validate_error_3") as string)
      );
    }
  };

  const nameValidation: RegisterOptions = {
    required: true,
    maxLength: {
      value: 30,
      message: t("modal.source.create.validation.more-100"),
    },
    validate: {
      validateSameName,
      validateSymbols,
      validatePG,
    },
  };

  const getFileRowCount = (fileObj: File) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = new Uint8Array(e?.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const range = XLSX.utils.decode_range(worksheet["!ref"] as any);
      const rowCount = range.e.r + 1; // +1 because the row index is zero-based

      setRowCount(rowCount);
    };

    reader.readAsArrayBuffer(fileObj);
  };

  useEffect(() => {
    if (!fileField?.file) {
      setFile(addFileText);
    } else {
      const allowedFormat = "xlsx"; //TODO: make a universal variable like currentFileformat if there are other file formats
      const uploadFileFormat = fileField.file.name
        .split(".")
        .pop()
        .toLowerCase();
      if (allowedFormat !== uploadFileFormat) {
        setFile(addFileText);
        setShowCheckboxHeaders(false);
        return;
      }
      setFile("source.detail.card.actions.actions_button.upload_csv.end");
      setShowCheckboxHeaders(true);
      getFileRowCount(fileField?.file.originFileObj);
    }
  }, [fileField]);

  const submitForm = (data) => {
    const request: ICreateConnectionTypes = {
      storage_name: data.name as string,
      storage_conn_config: {} as IStorageConfigTypes,
      storage_datalayer_code: "",
      storage_datasource_code: ESourceCode.NONE,
    };

    if (watchSourceType === ESourceCode.XLSX) {
      const fileObj = data.fileXlsx?.file.originFileObj;
      request.file = fileObj;
      request.storage_datasource_code = ESourceCode.XLSX;
      request.storage_datalayer_code = "dl_source";
      request.storage_conn_config = {
        headers: isHeaders,
        rows: isHeaders ? [Number(data.from), Number(data.to)] : [],
      };
    }

    if (watchSourceType === ESourceCode.PG) {
      request.storage_conn_config = {
        host: data.host,
        port: data.port,
        db: data.db,
        user: data.user,
        password: data.password,
      };
      request.storage_datasource_code = ESourceCode.PG;
      request.storage_datalayer_code = "dl_source";
    }

    if (watchSourceType === ESourceCode.MONGO_DB) {
      request.storage_conn_config = {
        host: data.host,
        port: data.port,
        db: data.db,
        user: data.user,
        password: data.password,
      };
      request.storage_datasource_code = ESourceCode.MONGO_DB;
      request.storage_datalayer_code = "dl_source";
    }

    if (watchSourceType === ESourceCode.ORACLE) {
      request.storage_conn_config = {
        host: data.host,
        port: data.port,
        user: data.user,
        password: data.password,
        service_name: data.service_name,
      };
      request.storage_datasource_code = ESourceCode.ORACLE;
      request.storage_datalayer_code = "dl_source";
    }

    if (origin !== null) {
      dispatch(
        editConnectionsById({ id: origin.id, connection: request })
      ).then(() => {
        onCancel();
        dispatch(fetchConnections());
      });
      return;
    }
    dispatch(createConnections(request)).then(() => {
      onCancel();
      reset();
      setValue("source_type", ESourceCode.NONE);
      dispatch(fetchConnections());
    });
  };

  const dummyRequest = (action: any) => {
    setTimeout(() => {
      action.onSuccess("ok");
    });
  };

  const props: UploadProps = {
    name: "fileXlsx",
    multiple: false,
    maxCount: 1,
    accept: ".xlsx",
    customRequest: dummyRequest,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove() {
      setTimeout(() => {
        setValue("fileXlsx", undefined);
        clearErrors("fileXlsx");
        setFile(addFileText);
        setShowCheckboxHeaders(false);
      }, 500);
    },
  };

  const tooltipTitle = t("modal.source.create.xlsx_tooltip");
  return (
    <>
      <form
        onSubmit={handleSubmit((data) => submitForm(data))}
        className={s.addSourceForm}
      >
        <h2 className={s.formTitles}>{t("modal.source.create.title.one")}</h2>
        <AppInput
          rules={nameValidation}
          errors={errors}
          autoComplete={autoComplete}
          control={control}
          name={"name"}
          placeholder={t("modal.source.create.placeholder.name") as string}
        />

        <AppInput
          rules={{ required: true }}
          autoComplete={autoComplete}
          control={control}
          name={"source_type"}
          placeholder={t("modal.source.create.placeholder.type") as string}
        >
          <>
            {connectionTypes.map((e: any, i: number) => {
              return (
                <Option value={e.id} key={i}>
                  {e.name}
                </Option>
              );
            })}
          </>
          <Option value={ESourceCode.PG} key={ESourceCode.PG}>
            PostgreSQL
          </Option>
          <Option value={ESourceCode.ORACLE} key={ESourceCode.ORACLE}>
            Oracle
          </Option>
          {/* uncomment when mongo on backend will be ready */}
          {/* <Option value={ESourceCode.MONGO_DB} key={ESourceCode.MONGO_DB}>
            MongoDB
          </Option> */}
          <Option value={ESourceCode.XLSX} key={ESourceCode.XLSX}>
            XLSX
          </Option>
        </AppInput>

        {watchSourceType === ESourceCode.XLSX && (
          <>
            <div className={s.addFileRender}>
              <Controller
                control={control}
                rules={{ required: true, validate: { validateFileFormat } }}
                render={({ field }) => (
                  <Dragger {...props} {...field}>
                    <AppXlsxIcon />
                    <p>{t(file)}</p>
                  </Dragger>
                )}
                name="fileXlsx"
              />
              <ErrorMessage
                key={"fileXlsx"}
                name={"fileXlsx"}
                errors={errors}
                render={({ message }) => (
                  <span className={s.inputError}>{message}</span>
                )}
              />
            </div>
            {showCheckboxHeaders && (
              <div className={s.addFileCheckbox}>
                <AppCheckbox control={control} name={"headers"}>
                  <Checkbox
                    value={true}
                    onChange={() => {
                      setIsHeaders(!isHeaders);
                      unregister("from");
                      unregister("to");
                    }}
                  >
                    {t("modal.source.create.xlsx_checkbox")}
                  </Checkbox>
                  <></>
                </AppCheckbox>
              </div>
            )}
            {showCheckboxHeaders && isHeaders && (
              <>
                <div className={s.xlsxHeader}>
                  <div className={s.xlsxHeaderLeft}>
                    <h2 className={s.formTitles}>
                      {t("modal.source.create.xlsx_headers_list")}
                    </h2>
                    <Tooltip
                      title={tooltipTitle}
                      placement="topLeft"
                      color={"#fff"}
                    >
                      <span>
                        <AppQuestionIcon />
                      </span>
                    </Tooltip>
                  </div>
                  <div className={s.xlsxHeaderRight}>
                    {t("modal.source.create.xlsx_rows")}:{" "}
                    <span className={s.xlsxLines}>{rowCount}</span>
                  </div>
                </div>
                <div className={s.rangeBlock}>
                  <AppInput
                    rules={{
                      required: isHeaders,
                      validate: {
                        validateRowsMoreZero,
                        validateRowsLessCountOfRows,
                      },
                    }}
                    control={control}
                    name={"from"}
                    placeholder={t("modal.source.create.xlsx_from")}
                    wrapperClassName={s.rangeBlockInputWrapper}
                  />
                  <div className={s.rangeBlockCenter} />
                  <AppInput
                    rules={{
                      required: isHeaders,
                      validate: {
                        validateRowsMoreZero,
                        validateRowsLessCountOfRows,
                        validateRowsFromLessTo,
                      },
                    }}
                    control={control}
                    name={"to"}
                    placeholder={t("modal.source.create.xlsx_to")}
                    wrapperClassName={s.rangeBlockInputWrapper}
                  />
                </div>
                {["from", "to"].map((item) => {
                  return (
                    <ErrorMessage
                      key={item}
                      name={item as any}
                      errors={errors}
                      render={({ message }) => (
                        <span className={s.inputError}>{message}</span>
                      )}
                    />
                  );
                })}
              </>
            )}
          </>
        )}

        {(watchSourceType === ESourceCode.PG ||
          watchSourceType === ESourceCode.MONGO_DB) && (
          <>
            <AppInput
              rules={{ required: true }}
              control={control}
              name={"host"}
              placeholder={
                t("modal.source.create.placeholder.address") as string
              }
            />
            <AppInput
              rules={{ required: true }}
              control={control}
              name={"port"}
              placeholder={t("modal.source.create.placeholder.port") as string}
            />
            <AppInput
              rules={{ required: true }}
              control={control}
              name={"db"}
              placeholder={
                t("modal.source.create.placeholder.data_base") as string
              }
            />
          </>
        )}

        {watchSourceType === ESourceCode.ORACLE && (
          <>
            <AppInput
              rules={{ required: true }}
              control={control}
              name={"host"}
              placeholder={
                t("modal.source.create.placeholder.address") as string
              }
            />
            <AppInput
              rules={{ required: true }}
              control={control}
              name={"port"}
              placeholder={t("modal.source.create.placeholder.port") as string}
            />
            <AppInput
              rules={{ required: true }}
              control={control}
              name={"service_name"}
              placeholder={
                t("modal.source.create.placeholder.service_name") as string
              }
            />
          </>
        )}

        {watchSourceType !== undefined &&
          watchSourceType !== ESourceCode.NONE &&
          watchSourceType !== ESourceCode.XLSX && (
            <>
              <h2 className={s.formTitles}>
                {t("modal.source.create.title.auth")}
              </h2>
              <AppInput
                rules={{ required: watchSourceType !== ESourceCode.MONGO_DB }}
                autoComplete={autoComplete}
                control={control}
                name={"user"}
                placeholder={
                  t("modal.source.create.placeholder.login") as string
                }
              />
              <AppInput
                rules={{ required: watchSourceType !== ESourceCode.MONGO_DB }}
                autoComplete={autoComplete}
                control={control}
                name={"password"}
                placeholder={
                  t("modal.source.create.placeholder.password") as string
                }
              />
            </>
          )}
        <AppButton disabled={!isValid} htmlType={"submit"}>
          {t(
            origin !== null
              ? "modal.source.create.action.save_changes"
              : "modal.source.create.action.create"
          )}
        </AppButton>
      </form>
    </>
  );
};
