import { Collapse } from "antd";
import { useForm } from "react-hook-form";
import cn from "classnames";

import { AppInput } from "../../app-input/app-input.component";
import { AppEntityIcon } from "../../icons/app-entity.icon";
import { AppSchemeIcon } from "../../icons/app-scheme.icon";
import s from "../create-process-modal.module.scss";
import { getStorageIdByKey } from "./helper";

import { StepFooter, StepLayout } from "./step-layout.component";
import { Alert } from "../../ui/app-alert/app-alert.component";

const { Panel } = Collapse;

const SQL_PLACEHOLDER = `SELECT name, description 
FROM pg_test.public_test1 as test1 
INNER JOIN  pg_test.public_test2 as test2 
ON test1.name = test2.name`;

export const Step3 = ({ storages, processData, onSubmit }: any) => {
  const formInstance = useForm({
    mode: "onChange",
    defaultValues: {
      process_sql: processData.process_sql,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = formInstance;

  const submitForm = (data: any) => {
    onSubmit(data);
  };

  const rawEntities = processData?.rawEntities || {};
  const storageList = Object.keys(rawEntities).map((key) => {
    const storageId = getStorageIdByKey(key);
    const storage = storages.find(
      ({ id }: { id: number }) => Number(id) === Number(storageId)
    );
    storage.selectedEntities = storage.entities.filter((entity: any) =>
      processData.rawEntities[key].includes(entity.id)
    );

    return storage;
  });

  return (
    <StepLayout>
      <form
        className={s.formWrapper}
        onSubmit={handleSubmit((data) => submitForm(data))}
      >
        <div>
          <h3 className={s.formTitle}>Список источников:</h3>

          {!storageList.length ? (
            <p>Выберите сущности для установления связей между ними</p>
          ) : null}

          <div className={s.entitiesList}>
            {storageList.map((storage, index) => {
              return (
                <Collapse className={s.collapse} key={index}>
                  <Panel
                    header={
                      <div className={s.collapseHeader}>
                        <AppSchemeIcon />
                        Источник {storage.storage_name}
                      </div>
                    }
                    key="1"
                  >
                    <>
                      {storage.selectedEntities.map(
                        (entity: any, index: number) => {
                          return (
                            <div className={s.collapseEntity} key={index}>
                              <div
                                className={cn(s.collapseEntityLine, {
                                  [s.collapseEntityLineLast]:
                                    storage.selectedEntities.length - 1 ===
                                    index,
                                })}
                              >
                                <span
                                  className={s.collapseEntityLineLeaf}
                                ></span>
                              </div>
                              <div className={s.collapseEntityName}>
                                <AppEntityIcon width="16" height="16" />
                                {entity.entity_name}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </>
                  </Panel>
                </Collapse>
              );
            })}
          </div>

          <h3 className={s.formTitle}>Укажите SQL-запрос</h3>

          <p className={s.helpSql}>
            <Alert>
              <div>
                В наших базах данных наименование таблиц хранится в виде:
                <b>НазваниеИсточника.СхемаТаблицы_НазваниеТаблицы</b>. Например,
                выбранная на втором шаге таблица с наименованием{" "}
                <b className={s.textBlue}>test</b>, находящася в схеме{" "}
                <b className={s.textRed}>public</b>, в <i>источнике</i>{" "}
                <b>pg_test</b> в sql-запросе должна выглядеть следующим образом:
                <b>pg_test.</b>
                <b className={s.textRed}>public</b>
                <b className={s.textBlue}>_test</b>
              </div>
            </Alert>
          </p>
          <AppInput
            rules={{ required: "Заполните это поле" }}
            errors={errors}
            control={control}
            name={"process_sql"}
            placeholder={SQL_PLACEHOLDER}
            isTextArea={true}
          />
        </div>

        <StepFooter disabledNext={!isValid} />
      </form>
    </StepLayout>
  );
};
