import React, { useMemo } from "react";
import { Modal } from "antd";
import { LowCodeEditEntityForm } from "./low-code-edit-entity-form.component";
import { IStorageEntriesType } from "../../../../store/types/storages.types";
import type { Edge, Node } from "reactflow";
import { useTranslation } from "react-i18next";
import s from "./low-code-edit-entity-modal.module.scss";

type StorageType = {
  id: number;
  storage_name: string;
  storage_name_slug: string;
  entities: IStorageEntriesType[];
};

type EntityField = {
  id: string;
  name: string;
};

type PropTypes = {
  open: boolean;
  storage: StorageType | undefined;
  node: Node;
  edges: Edge[];
  onCancel: () => void;
  onConfirm: (entities: EntityField[], deletedFieldsWithEdges: []) => void;
};

export const LowCodeEditEntityModal: React.FC<PropTypes> = ({
  open,
  edges,
  storage,
  node,
  onCancel,
  onConfirm,
}) => {
  if (!open) return null;
  const { t } = useTranslation();

  const title = (
    <>
      <span className={s.modalHeader}>
        {t("page.process.form.step.lowcode.entity.edit.header")}
      </span>
      <div className={s.modalDescription}>
        {t("page.process.form.step.lowcode.entity.edit.description")}
        <a>{node?.data?.title}</a>
      </div>
    </>
  );

  const createNodeFields = (entities: string[]) =>
    entities.map((entityId) => {
      const { id, entity_name } = (storage as StorageType).entities.find(
        ({ id }) => Number(id) === Number(entityId)
      ) as IStorageEntriesType;
      return {
        id: String(id),
        name: entity_name,
      };
    });

  const connectionData = useMemo(() => {
    if (!edges?.length) return null;
    return edges.filter((item) => item.source === String(storage?.id));
  }, [storage]);

  const handleConfirm = (saved: string[], deleted: string[]) => {
    const nodeFields = createNodeFields(saved);
    const deletedEdges: any = edges.filter(({ sourceHandle }) => {
      return deleted.includes(String(sourceHandle));
    });

    onConfirm(nodeFields, deletedEdges);
  };

  return (
    <Modal
      destroyOnClose={true}
      open={open}
      footer={false}
      onCancel={onCancel}
      title={title}
      wrapClassName="low-code-edit-entity-modal"
      data-testid="low-code-edit-entity-modal"
    >
      <LowCodeEditEntityForm
        storageName={storage?.storage_name}
        connections={connectionData}
        storageOptions={storage?.entities.map(({ id, entity_name }) => ({
          value: String(id),
          label: entity_name,
        }))}
        nodeValues={node ? node.data.fields.map(({ id }) => String(id)) : []}
        onSave={handleConfirm}
      />
    </Modal>
  );
};
