import cn from "classnames";
import s from "../aside.module.scss";
import { AppSourceIcon } from "../../../../components/icons/app-source.icon";
import { AppModelIcon } from "../../../../components/icons/app-model.icon";
import { AppInput } from "../../../../components/app-input/app-input.component";
import { AppSearchIcon } from "../../../../components/icons/app-search.icon";
import { useForm } from "react-hook-form";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { IStorageType } from "../../../../store/types/storages.types";

type PropTypes = {
  storages: IStorageType[] | null;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: string,
    nodeId?: string | number
  ) => void;
};

export const StoragesList: React.FC<PropTypes> = ({
  storages,
  handleDragStart,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");

  const { control } = useForm({ mode: "onChange" });

  const onChangeSearch = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearch(value);
  };

  if (!storages) return null;

  const _storages = search
    ? storages.filter((s: IStorageType) =>
        s.storage_name.toLowerCase().includes(search.toLowerCase())
      )
    : storages;

  return (
    <div>
      <div className={s.asideBody}>
        <div className={s.searchWrapper}>
          <AppInput
            icon={<AppSearchIcon width="16" height="16" />}
            sharedStyles={s.searchInputWrapper}
            inputStyles={s.searchInput}
            onChange={onChangeSearch}
            name={"search"}
            control={control}
            placeholder={
              t("page.process.form.step.lowcode.search.placeholder") || ""
            }
          />
        </div>
        <div className={cn(s.asideList, s._nodeList)} role="listbox">
          {_storages.map((item) => (
            <div
              key={item.id}
              className={cn(s.asideItem)}
              onDragStart={(event) =>
                handleDragStart(event, "storageNode", item.id)
              }
              draggable
              role="option"
            >
              <div className={s.asideItemCol}>
                <AppSourceIcon />
                <div className={s.asideItemContainer}>
                  <div className={s.asideItemTitle}>{item.storage_name}</div>
                  <div className={s.asideItemSlug}>
                    {item.storage_name_slug}
                  </div>
                </div>
              </div>
              <AppModelIcon />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
