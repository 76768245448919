import React, { useCallback } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { AppDeleteIcon, PlusIcon } from "../../../../components/icons";
import {
  Join,
  JoinDetails,
  MergeIncomerType,
} from "../../../../store/types/low-code.types";
import MergeFormSettingsStepper from "./low-code-merge-form-settings-stepper.component";

import s from "./low-code-merge-modal.module.scss";

type PropTypes = {
  source: MergeIncomerType[];
  leftEntityId: string;
  isValid: boolean;
  joins: Join[];
  addJoin: () => void;
  removeJoin: (id: number) => void;
  onChange: (joins: Join[]) => void;
  pageMode?: string;
};

export const MergeFormSettings: React.FC<PropTypes> = ({
  source,
  leftEntityId,
  isValid,
  joins,
  addJoin,
  removeJoin,
  onChange,
  pageMode,
}) => {
  const { t } = useTranslation();
  const findEntity = useCallback(() => {
    const { entity } = source.find(
      ({ entity }) => String(entity.id) === String(leftEntityId)
    ) as MergeIncomerType;
    return entity;
  }, [leftEntityId]);

  const appendEntities = useCallback(() => {
    return source.map(({ entity, storage: { storage_name } }) => {
      return { ...entity, storage_name };
    });
  }, [leftEntityId]);

  return (
    <>
      {joins.map((join) => (
        <div className={s.join} key={join.index}>
          <div className={s.title}>
            <div>
              {t("page.process.form.step.lowcode.merge.link")} {join.index}
            </div>
            {joins.length > 1 && (
              <button type="button" onClick={() => removeJoin(join.index)}>
                <AppDeleteIcon />
              </button>
            )}
          </div>
          <MergeFormSettingsStepper
            join={join}
            leftEntity={findEntity()}
            entities={appendEntities()}
            onChange={(
              isValid: boolean,
              left?: JoinDetails,
              right?: JoinDetails,
              variant?
            ) => {
              const item = joins.find((j) => j.index === join.index);
              const leftIncomer = source.find(
                ({ entity }) => String(entity.id) === String(left?.entityId)
              );
              const rightIncomer = source.find(
                ({ entity }) => String(entity.id) === String(right?.entityId)
              );

              if (item) {
                item.isValid = isValid;

                if (leftIncomer) {
                  const attr = leftIncomer.entity.attributes.find(
                    ({ id }) => String(id) === String(left?.attributeId)
                  ) as any;
                  item.left = {
                    type: leftIncomer.type,
                    id: leftIncomer.storage.id,
                    storageName: leftIncomer.storage.storage_name,
                    storageNameSlug: leftIncomer.storage.storage_name_slug,
                    entityId: String(leftIncomer.entity.id),
                    entityName: leftIncomer.entity.entity_name,
                    entityNameSlug: leftIncomer.entity.entity_name_slug,
                    entitySchema: leftIncomer.entity.entity_schema,
                    entitySchemaSlug: leftIncomer.entity.entity_schema_slug,
                    attributeId: String(left?.attributeId),
                    attributeName: attr.attribute_name,
                    attributeNameSlug: attr.attribute_name_slug,
                    attributeType: attr.attribute_type,
                  };
                }
                if (rightIncomer) {
                  const attr = rightIncomer.entity.attributes.find(
                    ({ id }) => String(id) === String(right?.attributeId)
                  ) as any;
                  item.right = {
                    type: rightIncomer.type,
                    id: rightIncomer.storage.id,
                    storageName: rightIncomer.storage.storage_name,
                    storageNameSlug: rightIncomer.storage.storage_name_slug,
                    entityId: String(rightIncomer.entity.id),
                    entityName: rightIncomer.entity.entity_name,
                    entityNameSlug: rightIncomer.entity.entity_name_slug,
                    entitySchema: rightIncomer.entity.entity_schema,
                    entitySchemaSlug: rightIncomer.entity.entity_schema_slug,
                    attributeId: String(right?.attributeId),
                    attributeName: attr.attribute_name,
                    attributeNameSlug: attr.attribute_name_slug,
                    attributeType: attr.attribute_type,
                  };
                }
                item.variant = variant;
              }

              onChange(joins);
            }}
          />
        </div>
      ))}
      {pageMode !== "view" && (
        <button
          type="button"
          className={cn({
            [s.addButton]: isValid,
            [s.addButtonDisabled]: !isValid,
          })}
          onClick={addJoin}
        >
          {t("page.process.form.step.lowcode.merge.add")} &nbsp;
          <PlusIcon width="14" height="14" />
        </button>
      )}
    </>
  );
};
