import type {
  AttributeType,
  JoinItem,
  MergeDataType,
  MergeIncomerType,
} from "../../../store/types/low-code.types";
import { flatten, uniq } from "../../../utils/utils";
import type { Node } from "reactflow";

export class Merge {
  public data: MergeDataType | null;
  protected children = new Map<string, Merge>();
  public parent!: Merge | null;

  constructor(data?: MergeDataType | undefined | null) {
    this.data = data ?? null;
  }

  public getData() {
    return this.data;
  }

  public setParent(parent: Merge | null) {
    this.parent = parent;
  }

  public add(component: Merge): void {
    const key = `merge_${component?.data?.node.data.index}`;
    this.children.set(key, component);
    component.setParent(this);
  }

  public find(key: string): Merge | undefined {
    if (this.children.has(key)) {
      return this.children.get(key);
    }

    for (const child of this.children.values()) {
      return child.find(key);
    }
  }

  public remove(key: string): Node[] | undefined {
    if (this.children.has(key)) {
      const child = this.children.get(key);
      this.children.delete(key);
      return this.childrenNodes(child);
    }

    for (const child of this.children.values()) {
      return child.remove(key);
    }
  }

  public size() {
    return this.children.size;
  }

  private formEdgeId(join: JoinItem | undefined) {
    if (!join) return;

    const idx = this.data?.node.data.index;

    const source = join.id;
    const sourceHandle =
      join.type === "merge"
        ? join.entityId.replace("merge_", "source-merge-")
        : join.entityId;
    const target = `merge_${idx}`;
    const targetHandle = `target-merge-${idx}`;

    return `reactflow__edge-${source}${sourceHandle}-${target}${targetHandle}`;
  }

  public getEdges() {
    const entityIds = flatten(
      this.data?.joins.map(({ left, right }) => [
        this.formEdgeId(left),
        this.formEdgeId(right),
      ]) || []
    );

    const childEntityIds = Array.from(this.children.values()).map((component) =>
      component.getEdges()
    );

    return uniq(entityIds.concat(flatten(childEntityIds)));
  }

  public getEntities() {
    const entityIds = flatten(
      this.data?.joins.map(({ left, right }) => [
        Number(left?.entityId),
        Number(right?.entityId),
      ]) || []
    );

    const childEntityIds = Array.from(this.children.values()).map((component) =>
      component.getEntities()
    );

    return uniq(
      entityIds.concat(flatten(childEntityIds)).filter((id) => !isNaN(id))
    );
  }

  protected getAttributes() {
    const entityIds = flatten(
      this.data?.joins.map(({ left, right }) => [
        left?.entityId,
        right?.entityId,
      ]) || []
    );

    const allAttributes = flatten(
      this.data?.incomers.map(({ entity }) =>
        entity.attributes.map((attr: AttributeType) => ({
          id: attr.id,
          entity_id: entity.id,
          attribute_schema: entity.entity_name,
          attribute_name: attr.attribute_name,
          attribute_name_slug: attr.attribute_name_slug,
          attribute_type: attr.attribute_type,
        }))
      ) || []
    );

    return allAttributes.filter(({ entity_id }) =>
      entityIds.includes(String(entity_id))
    );
  }

  getEntitiyIds() {
    return flatten(
      this.data?.joins.map(({ left, right }) => [
        left?.entityId,
        right?.entityId,
      ]) || []
    );
  }

  public getResult(): MergeIncomerType {
    const key = `merge_${this.data?.node.data.index}`;

    return {
      type: "merge",
      node: this.data?.node as Node,
      entity: {
        id: key,
        entity_name: this.data?.name || key,
        entity_name_slug: key,
        entity_schema: key,
        entity_schema_slug: key,
        attributes: this.getAttributes(),
      },
      storage: {
        id: key,
        storage_name: "",
        storage_name_slug: "",
        entities: [],
      },
    };
  }

  public childrenNodes(child: Merge | undefined): Node[] {
    return flatten(
      Array.from(child?.children.values() || []).map(
        (transform) => transform?.data?.incomers.map(({ node }) => node) || []
      )
    );
  }

  public toObject() {
    const key = `merge_${this.data?.node.data.index}`;

    const leaf =
      this.data === null
        ? []
        : [
            {
              index: this.data?.node.data.index,
              type: "merge",
              name: this.data.name,
              parent:
                this.parent && this.parent.data
                  ? { index: this.parent.data?.node.data.index, type: "merge" }
                  : null,
              children: Array.from(this.children.values()).map((child) => ({
                index: child.data?.node.data.index,
                type: "merge",
              })),
              settings: this.data.joins,
              results: {
                type: "process",
                id: 0,
                entityId: 0,
                entityName: key,
                attributes: this.getAttributes().map((item) => ({
                  id: item.id,
                  entityId: item.entity_id,
                  attributeSchema: item.attribute_schema,
                  attributeName: item.attribute_name,
                  attributeNameSlug: item.attribute_name_slug,
                  attributeType: item.attribute_type,
                })),
              },
            },
          ];

    const childLeafs = Array.from(this.children.values()).map((component) =>
      component.toObject()
    );

    return leaf.concat(flatten(childLeafs));
  }
}
