import React, { ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import cn from "classnames";

import { AppSourceIcon } from "../icons/app-source.icon";
import { AppDomainIcon } from "../icons/app-domain.icon";
import { AppQualityIcon } from "../icons/app-quality.icon";
import { AppHypothesisIcon } from "../icons/app-hypothesis.icon";

import s from "./app-navigation.module.scss";

interface INavigationType {
  title: string;
  route: string;
  icon?: ReactNode;
  underMaintain: boolean;
  id: string;
}

const navigationRoutes: INavigationType[] = [
  {
    title: "navigation.source",
    route: "/home",
    icon: <AppSourceIcon />,
    underMaintain: false,
    id: "storage-nav",
  },
  {
    title: "navigation.processes",
    route: "/processes",
    icon: <AppDomainIcon />,
    underMaintain: false,
    id: "processes-nav",
  },
  {
    title: "navigation.quality",
    route: "/quality",
    icon: <AppQualityIcon />,
    underMaintain: false,
    id: "data-quality",
  },
  {
    title: "navigation.hypothesis",
    route: "/hypothesis",
    icon: <AppHypothesisIcon />,
    underMaintain: false,
    id: "hypothesis-nav",
  },
];

export const AppNavigation = () => {
  const { t } = useTranslation();
  const collapsedValue = localStorage.getItem("menuView") === "collapsed";
  const [collapsed, setCollapsed] = useState(collapsedValue);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    if (collapsedValue) {
      localStorage.setItem("menuView", "full");
    } else {
      localStorage.setItem("menuView", "collapsed");
    }
  };

  const setMaintain = (maintain: boolean) => {
    return {
      [s._maintain]: maintain,
    };
  };

  return (
    <div
      className={cn(s.navigation, {
        [s.collapsed]: collapsed,
      })}
    >
      {navigationRoutes.map((nav, i) => {
        return (
          <NavLink
            id={nav.id}
            to={nav.route}
            key={i}
            className={({ isActive }) => {
              return !isActive
                ? cn(s.navBlock, setMaintain(nav.underMaintain), {
                    [s.navBlockCollapsed]: collapsed,
                  })
                : cn(
                    s.navBlock,
                    s.navBlockActive,
                    setMaintain(nav.underMaintain),
                    {
                      [s.navBlockCollapsed]: collapsed,
                    }
                  );
            }}
          >
            {nav.icon} {collapsed ? "" : <p>{t(nav.title)}</p>}
            {nav.underMaintain && (
              <div className={s.underMaintain}>
                {t("app.navigation.maintain")}
              </div>
            )}
          </NavLink>
        );
      })}
      <div className={cn(s.navBtnBlock)}>
        <button
          onClick={toggleCollapsed}
          className={cn(s.navBtn, {
            [s.navBtnCollapsed]: collapsed,
          })}
        >
          {/* {collapsed ? "" : <div>{t("app.navigation.collapse")}</div>} */}
          {collapsed ? (
            <RightOutlined style={{ color: "#0057FF" }} />
          ) : (
            <LeftOutlined style={{ color: "#0057FF" }} />
          )}
        </button>
      </div>
    </div>
  );
};
