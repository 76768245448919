import cn from "classnames";
import s from "../aside.module.scss";
import { AppModelIcon } from "../../../../components/icons/app-model.icon";
import { useTranslation } from "react-i18next";
import { AppMergeIcon } from "../../../../components/icons/app-merge.icon";

type PropTypes = {
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: string,
    nodeId?: string | number
  ) => void;
};

export const TransformationsList: React.FC<PropTypes> = ({
  handleDragStart,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={s.asideBody}>
        <div className={s.asideList} role="listbox">
          <div
            className={cn(s.asideItem)}
            onDragStart={(event) =>
              handleDragStart(event, "mergeTransformNode")
            }
            draggable
            role="option"
          >
            <div className={s.asideItemCol}>
              <AppMergeIcon
                width="14"
                height="14"
                className={s.transfromIcon}
              />
              <div>
                <div className={s.asideItemTitle}>
                  {t(
                    "page.process.form.step.lowcode.transformation.node.merge"
                  )}
                </div>
              </div>
            </div>
            <AppModelIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
