import { Drawer } from "antd";
import { useEffect, useState } from "react";
import cn from "classnames";
import { format } from "sql-formatter";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Tooltip } from "antd";

import {
  AppButton,
  EButtonSize,
} from "../../../../components/app-button/app-button.component";
import { AppArrowIcon } from "../../../../components/icons/app-arrow.icon";
import s from "./generate-sql-drawer.module.scss";
import { useAppDispatch } from "../../../../store/hooks";
import { processGenerateSql } from "../../../../api/processes/processes-actions.api";
import { AppLogoLoader } from "../../../../components/ui/app-animated-logo/app-animated-logo.component";
import { AppInfoIcon } from "../../../../components/icons/app-info.icon";
import { useTranslation } from "react-i18next";
import { showMessage } from "../../../../store/snackbar/snackbar.slice";
import { ESnackbarState } from "../../../../store/types/snackbar.types";
import { AppCopyIcon } from "../../../../components/icons/app-copy.icon";

export const GenerateSqlDrawer = ({ className, tree }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sql, setSql] = useState<string>("");

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  useEffect(() => {
    if (openDrawer && tree?.size()) {
      setLoading(true);
      dispatch(processGenerateSql(tree.toObject()))
        .unwrap()
        .then((res) => {
          setLoading(false);
          setSql(res.data.sql);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
          setSql("");
        });
    } else {
      setError(false);
      setSql("");
    }
  }, [openDrawer, tree?.size()]);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleCopySql = async () => {
    try {
      await navigator.clipboard.writeText(sql);
      dispatch(
        showMessage({
          message: "page.process.form.step.lowcode.sql-generate.copy.success",
          type: ESnackbarState.SUCCESS,
        })
      );
    } catch (err) {
      dispatch(
        showMessage({
          message: "page.process.form.step.lowcode.sql-generate.copy.error",
          type: ESnackbarState.ERROR,
        })
      );
    }
  };

  const formatSQL = (sql: string) => {
    return format(sql, {
      language: "sql",
      tabWidth: 2,
      keywordCase: "upper",
      linesBetweenQueries: 2,
    });
  };

  return (
    <>
      {tree?.size() ? (
        <div className={cn(s.cardContainer, className)}>
          <div className={s.cardLabel}>
            {t("page.process.form.step.lowcode.sql-generate.label")}
          </div>
          <AppButton buttonSize={EButtonSize.FILL} onClick={handleOpenDrawer}>
            {t("page.process.form.step.lowcode.sql-generate.button")}
          </AppButton>
        </div>
      ) : null}

      <Drawer
        open={openDrawer}
        width={496}
        bodyStyle={{ padding: "unset" }}
        headerStyle={{ display: "none" }}
        placement="right"
        keyboard={true}
        closable={false}
        mask={false}
        getContainer={false}
        style={{
          position: "absolute",
          top: "-24px",
          right: "-24px",
          zIndex: 99,
        }}
        onClose={handleCloseDrawer}
      >
        <div className={s.drawerHeader}>
          <div className={s.drawerHeaderOutButton} onClick={handleCloseDrawer}>
            <AppArrowIcon side={"left"} width={"16"} height={"16"} />
            {t("page.process.form.step.lowcode.sql-generate.title")}
          </div>
          <Tooltip
            placement={"bottomRight"}
            title={t("page.process.form.tooltip.copy.sql")}
            color={"var(--white)"}
          >
            <AppButton
              buttonSize={EButtonSize.HUG}
              sharedStyles={s.drawerHeaderButton}
              isOutline
              onClick={handleCopySql}
              aria-label="copy-sql"
            >
              <AppCopyIcon />
            </AppButton>
          </Tooltip>
        </div>
        <div className={s.drawerBody}>
          {loading && (
            <div className={cn(s.drawerAction)}>
              <AppLogoLoader loading={true}></AppLogoLoader>
            </div>
          )}
          {error && (
            <div className={cn(s.drawerAction)}>
              <div className={s.drawerActionError}>
                <AppInfoIcon sharedStyles={s.drawerActionErrorIcon} />
                <span>
                  {t("page.process.form.step.lowcode.sql-generate.error")}
                </span>
              </div>
            </div>
          )}
          <SyntaxHighlighter language="sql" style={vs}>
            {formatSQL(sql)}
          </SyntaxHighlighter>
        </div>
      </Drawer>
    </>
  );
};
